import { object } from "yup";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory } from "react-router-dom";
import { Form } from "@shared/components/Form";
import Loading from "@shared/components/Loading";
import { BiLogOut } from "react-icons/bi";
import { reduceCollectedPaymentObject, getLocalToken } from "@shared/functions";
// import { logout } from "../store/slices/auth";
import { useSelector, useDispatch } from "react-redux";
import { selectNewLoansAll, selectTotalNewLoans } from "../store/slices/newLoans";
import { selectNewDebtors } from "../store/slices/newDebtors";
import { selectCommon } from "../store/slices/common";
import { selectQueues, selectSyncronizations } from "../store/slices/syncronization";
import { selectDevice } from "../store/slices/miscData";
import { selectCollectedAll, selectTotalCollected } from "../store/slices/payments";
// import { selectGastosAll, selectTotalGastos } from "../store/slices/gastos";
import WrongCuadreWarning from "../components/WrongCuadreWarning";
import { useAlert } from "react-alert";
import { uploadCobroCuadre } from "@shared/api";
import api from "../store/slices/api";
import { useAuth } from "../Hooks/useAuth";
import { checkSyncStatus, syncDatabases } from "../Hooks/usePouchSync";
import { localPouchPrefix, pouches_db } from "../config";
import { toast } from "react-toastify";
import useDataList from "../Hooks/useDataList";
import axiosApi from "@shared/server/api";

const Logout = () => {
  const auth = useAuth();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const queues = useSelector(selectQueues);
  const common = useSelector(selectCommon);
  // const gastos = useSelector(selectGastosAll);
  const newLoans = useSelector(selectNewLoansAll);
  const newDebtors = useSelector(selectNewDebtors);
  const collected = useSelector(selectCollectedAll);
  const syncronizations = useSelector(selectSyncronizations);
  const device = useSelector(selectDevice);
  // const totalGastos = useSelector(selectTotalGastos);
  const totalCollected = useSelector(selectTotalCollected);
  const totalNewLoans = useSelector(selectTotalNewLoans);
  const filteredQueues = queues.filter((q) => q.requiredData === true);

  const options = { include_deleted: true };
  const { data: gastos2, isLoading: g_Loading, error: gError } = useDataList(pouches_db.GASTOS, { options });
  const { data: newDebtors2, isLoading: d_loading, error: dError } = useDataList(pouches_db.NEW_DEBTORS, { options });
  const { data: newLoans2, isLoading: l_loading, error: nError } = useDataList(pouches_db.NEW_LOANS, { options });
  const { data: payments2, isLoading: p_loading, error: pError } = useDataList(pouches_db.PAYMENTS, { options });

  const { data: conflicst, isLoading: cLoading, error: cError } = useDataList(pouches_db.SYNC_CONFLICTS);
  const { data: pouchSyncs, isLoading: sLoading, error: sError } = useDataList(pouches_db.SYNC_HISTORY);

  if (gError || cError || sError || dError || pError || nError)
    return (
      <div className="alert alert-danger">
        <Typography variant="subtitle2" component="p">
          Hubo un problema cargando los datos.
        </Typography>
      </div>
    );

  if (g_Loading || cLoading || sLoading || l_loading || p_loading || d_loading) return <Loading />;

  const validation = object({});

  const button = { icon: <BiLogOut />, label: "Salir", variant: "contained", color: "secondary" };

  const totalGastos2 = gastos2.reduce((n, { amount }) => n + amount, 0);

  // const jsonValue = localStorage.getItem("localCommon");
  // const localCommon = jsonValue != null ? JSON.parse(jsonValue) : null;

  const backupDataOnLogout = async () => {
    const payments = reduceCollectedPaymentObject(collected);

    const efectivo =
      totalNewLoans.adelanto +
      common.bank_amount +
      totalNewLoans.actanotarial +
      totalCollected.collectedCash +
      totalCollected.mora -
      // totalGastos -
      totalGastos2 -
      totalNewLoans.entregas;

    const token = getLocalToken();

    const simplifiedSyncs = pouchSyncs.map((x) => ({
      _id: x._id,
      data_id: x.data_id,
      syncType: x.syncType,
      syncStatus: x.syncStatus,
    }));
    // payments: payments.reverse(), // Didnt want to work for unknown reason
    // newLoans: newLoans.reverse(), // Didnt want to work for unknown reason
    // gastos: gastos.reverse(), // Didnt want to work for unknown reason
    const data = {
      device: { ...device, token },
      payments: [...payments, ...payments2],
      newLoans: [...newLoans, ...newLoans2],
      gastos: [...gastos2],
      newDebtors: [...newDebtors, newDebtors2],
      cuadre: { ...common, efectivo },
      syncronizations: [...simplifiedSyncs, ...syncronizations],
      syncsConflicts: conflicst,
    };

    try {
      let prefix = localStorage.getItem(localPouchPrefix);

      localStorage.setItem("localCommonId", common.cuadre_id);

      if (!prefix || prefix == "wundefined_rundefined_undefined_cundefined") {
        prefix = `w${common.owner_id}_r${common.creditor_id}_${common.collect_date}_c${common.cuadre_id}`;
        localStorage.setItem(localPouchPrefix, prefix);
      }

      if (prefix) {
        const areSynced_payments = await checkSyncStatus(pouches_db.PAYMENTS, prefix + "_payments");
        const areSynced_gastos = await checkSyncStatus(pouches_db.GASTOS, prefix + "_gastos");
        const areSynced_newLoans = await checkSyncStatus(pouches_db.NEW_LOANS, prefix + "_loans");
        const areSynced_newDebtors = await checkSyncStatus(pouches_db.NEW_DEBTORS, prefix + "_debtors");

        if (!areSynced_payments) {
          console.log("Payment databases are not in sync!");
          await syncDatabases(pouches_db.PAYMENTS, prefix + "_payments");
        }

        if (!areSynced_gastos) {
          console.log("Gastos databases are not in sync!");
          await syncDatabases(pouches_db.GASTOS, prefix + "_gastos");
        }

        if (!areSynced_newLoans) {
          console.log("New Loans databases are not in sync!");
          await syncDatabases(pouches_db.NEW_LOANS, prefix + "_loans");
        }

        if (!areSynced_newDebtors) {
          console.log("New Debtors databases are not in sync!");
          await syncDatabases(pouches_db.NEW_DEBTORS, prefix + "_debtors");
        }

        await axiosApi.get(`/couchdb/${common.cuadre_id}/debtors-verification`);
        await axiosApi.get(`/couchdb/${common.cuadre_id}/loans-verification`);
        await axiosApi.get(`/couchdb/${common.cuadre_id}/payments-verification`);
        await axiosApi.get(`/couchdb/${common.cuadre_id}/gastos-verification`);
      }

      await uploadCobroCuadre(data);
      // dispatch(logout());
      dispatch(
        api.util.invalidateTags([
          "debtorsTag",
          "ownerConfigTag",
          "routeTag",
          "userTag",
          "userConfigTag",
          "permissionsTag",
        ])
      );

      localStorage.removeItem("recData");
      localStorage.removeItem("syncProps");
      localStorage.removeItem("newData");
      localStorage.removeItem("awaitingAction");

      auth.logout();

      history.replace("/");

      alert.success("Cuadre Cerrado!", { position: "bottom center", timeout: 10000 });
    } catch (error) {
      console.log(error);
      toast.error("Error Syncronizando Dato!", {
        autoClose: 5000,
      });
    }
  };

  const submithandler = () => backupDataOnLogout();

  return (
    <Card className="p-3 mt-2">
      <WrongCuadreWarning />

      {filteredQueues.length > 0 && (
        <Box component="div">
          <div className="alert alert-warning">
            <Typography variant="body1" component="p" paragraph>
              <b>Este dispositivo a&uacute;n tiene {filteredQueues.length} dato(s) que son requeridos sin guardar.</b>
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              Por favor, mant&eacute;ngase conectado al internet hasta que este mensaje desaparezca automaticamente.
            </Typography>
          </div>
        </Box>
      )}

      <Typography variant="h6" component="h3" gutterBottom>
        Salir y Guardar!
      </Typography>

      <Typography variant="body1" component="p" paragraph>
        Solamente los datos en este dispositivo seran reseteado!
      </Typography>

      <Typography variant="body1" component="p" paragraph>
        Esta seguro de querer resetear los datos de este dispositivo?
      </Typography>

      <div className="container mt-4">
        <Form initialValues={{}} validation={validation} submithandler={submithandler} button={button}></Form>
      </div>

      <div className="container mt-4">
        <Link className="text-danger" to="/">
          <u>Volver a Atr&aacute;s</u>
        </Link>
      </div>
    </Card>
  );
};

export default Logout;
