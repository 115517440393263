import { Route, Switch } from "react-router-dom";
import SharedRoutes from "@shared/SharedRoutes";
import ProtectedRoute from "@/components/ProtectedRoute";
import Index from "./pages/Index";
import Debtors from "./pages/Debtors";
import DebtorsOrganize from "./pages/DebtorsOrganize";
import Loans from "./pages/Loans";
import DebtorCreate from "./pages/DebtorCreate";
import CedulaSearch from "./pages/CedulaSearch";
import Profile from "./pages/Profile";
import NewLoans from "./pages/NewLoans";
import Contact from "./pages/Contact";
import Logout from "./pages/Logout";
// import Referrals from "./pages/Referrals";
import DebtorEdit from "./pages/DebtorEdit";
import DeletePayment from "./pages/DeletePayment";
import DeleteNewLoan from "./pages/DeleteNewLoan";
import ModifyEntrega from "./pages/ModifyEntrega";
// import UserLookup from "./pages/UserLookup";
import Collected from "./pages/Collected";
import LoanCreate from "./pages/LoanCreate";
import LoanRenewal from "./pages/LoanRenewal";
import LoansSync from "./pages/LoansSync";
import PaymentCreate from "./pages/PaymentCreate";
import Cuadre from "./pages/Cuadre";
import Syncronization from "./pages/Syncronization";
import Login from "./pages/Login";
import MoreTools from "./pages/MoreTools";
import RedirectPage from "./pages/RedirectPage";
import LoanDetail from "./pages/LoanDetail";
import PaymentReceipt from "./pages/PaymentReceipt";
import PouchTesting from "./pages/PouchTesting";
import Spenses from "./pages/Spenses";
// import CameraPermission from "./pages/CameraPermission";
import DebtorLoansCheck from "./pages/DebtorLoansCheck";
import UserConfig from "./pages/UserConfig";
import LoanPayments from "./pages/LoanPayments";
import LoanContract from "./pages/LoanContract";
import DebtorProfile from "./pages/DebtorProfile";
import Dashboard from "./pages/Dashboard";
import AccessCodes from "./pages/AccessCodes";
import LoanSignature from "./pages/LoanSignature";
// import RenewalUpload from "./pages/CameraComponent";
// import LoanApproval from "./pages/LoanApproval";
import DataCreditoSearch from "./pages/DataCreditoSearch";
import DebtorBlackList from "./pages/DebtorBlackList";
import UploadImage from "./pages/UploadImage";
import PasswordNew from "./pages/PasswordNew";
import CustomerDisabled from "./pages/CustomerDisabled";
import SWRFetcher from "./pages/SWRFetcher";
// import SWRCuadreInit from "./pages/SWRCuadreInit";
// import InitializeCommon from "./pages/InitializeCommon";
import UserPersonal from "./pages/UserPersonal";
import UserUsername from "./pages/UserUsername";
import UserPassword from "./pages/UserPassword";
// import PasswordResetMethod from "./pages/PasswordResetMethod";
// import PasswordPhoneVerify from "./pages/PasswordPhoneVerify";
import VerifySavedData from "./pages/VerifySavedData";
// import Redux from "./pages/Redux";
import ClearAppData from "./pages/ClearAppData";
import UploadSyncState from "./pages/UploadSyncState";
import UploadAppState from "./pages/UploadAppState";
import OfflineData from "./pages/OfflineData";

const Routes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute path="/pouch" render={(props) => <PouchTesting {...props} />} />
        {/* <ProtectedRoute path="/referrals" render={(props) => <Referrals {...props} />} /> */}
        <ProtectedRoute path="/user/personal/:id" render={(props) => <UserPersonal {...props} />} />
        <ProtectedRoute path="/user/username/:id" render={(props) => <UserUsername {...props} />} />
        <ProtectedRoute path="/user/password/:id" render={(props) => <UserPassword {...props} />} />
        <ProtectedRoute path="/dashboard" render={(props) => <Dashboard {...props} />} />
        <ProtectedRoute path="/loan/detail/:id/:id2" render={(props) => <LoanDetail {...props} />} />
        <ProtectedRoute path="/payment/receipt/:id/:id2/:print?" render={(props) => <PaymentReceipt {...props} />} />
        <ProtectedRoute path="/payment/create/:id" render={(props) => <PaymentCreate {...props} />} />
        <ProtectedRoute path="/collected" render={(props) => <Collected {...props} />} />
        <ProtectedRoute path="/loans" render={(props) => <Loans {...props} />} />
        <ProtectedRoute path="/entregas" render={(props) => <NewLoans {...props} />} />
        <ProtectedRoute path="/spenses" render={(props) => <Spenses {...props} />} />
        <ProtectedRoute path="/loan/renewal/:id/:id2" render={(props) => <LoanRenewal {...props} />} />
        <ProtectedRoute path="/swr/fetcher" render={(props) => <SWRFetcher {...props} />} />
        {/* <ProtectedRoute path="/swr/cuadre/init" render={(props) => <SWRCuadreInit {...props} />} /> */}
        {/* <ProtectedRoute path="/initialize/common" render={(props) => <InitializeCommon {...props} />} /> */}
        <ProtectedRoute path="/loan/create/:id" render={(props) => <LoanCreate {...props} />} />
        <ProtectedRoute path="/loan/sync" render={(props) => <LoansSync {...props} />} />
        <ProtectedRoute path="/debtors/organize" render={(props) => <DebtorsOrganize {...props} />} />
        <ProtectedRoute path="/debtors" render={(props) => <Debtors {...props} />} />
        <ProtectedRoute path="/cuadre" render={(props) => <Cuadre {...props} />} />
        <ProtectedRoute path="/offline-data" render={(props) => <OfflineData {...props} />} />
        <ProtectedRoute
          path="/debtor/loans/check/:dUn/:ced?/:mId?"
          render={(props) => <DebtorLoansCheck {...props} />}
        />
        <ProtectedRoute path="/redirect/page/:dUn/:mId/:ced?" render={(props) => <RedirectPage {...props} />} />
        <ProtectedRoute path="/loan/payments/:id/:id2" render={(props) => <LoanPayments {...props} />} />
        <ProtectedRoute path="/view/loan/signature/:id/:id2/:id3" render={(props) => <LoanContract {...props} />} />
        <ProtectedRoute path="/debtor/create/:ced?" render={(props) => <DebtorCreate {...props} />} />
        <ProtectedRoute path="/cedula/search" render={(props) => <CedulaSearch {...props} />} />
        {/* <ProtectedRoute path="/camera/permission/:id" render={(props) => <CameraPermission {...props} />} /> */}
        <ProtectedRoute path="/debtor/edit/:id" render={(props) => <DebtorEdit {...props} />} />
        <ProtectedRoute path="/user/config" render={(props) => <UserConfig {...props} />} />
        <ProtectedRoute path="/access/codes" render={(props) => <AccessCodes {...props} />} />
        <ProtectedRoute path="/debtor/profile/:id" render={(props) => <DebtorProfile {...props} />} />
        <ProtectedRoute path="/debtor/upload/:id/:page?" render={(props) => <UploadImage {...props} />} />
        <ProtectedRoute path="/data/credito" render={(props) => <DataCreditoSearch {...props} />} />
        <ProtectedRoute path="/black/list" render={(props) => <DebtorBlackList {...props} />} />
        <ProtectedRoute path="/loan/signature/:id" render={(props) => <LoanSignature {...props} />} />
        {/* <ProtectedRoute path="/renewal/upload/:id" render={(props) => <RenewalUpload {...props} />} /> */}
        {/* <ProtectedRoute path="/loan/approval" render={(props) => <LoanApproval {...props} />} /> */}
        <ProtectedRoute path="/newloan/modify/:id" render={(props) => <ModifyEntrega {...props} />} />
        <ProtectedRoute path="/delete/newloan/:id" render={(props) => <DeleteNewLoan {...props} />} />
        <ProtectedRoute path="/delete/payment/:id" render={(props) => <DeletePayment {...props} />} />
        <ProtectedRoute path="/profile" render={(props) => <Profile {...props} />} />
        <ProtectedRoute path="/Verify/Saved/data/:id" render={(props) => <VerifySavedData {...props} />} />
        <Route path="/logout" render={(props) => <Logout {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        {/* <Route path="/user/lookup" render={(props) => <UserLookup {...props} />} /> */}
        <Route path="/password/new/:id/:salt/:time" render={(props) => <PasswordNew {...props} />} />
        {/* <Route path="/password/resetmethod" render={(props) => <PasswordResetMethod {...props} />} /> */}
        {/* <Route path="/password/phone/verify" render={(props) => <PasswordPhoneVerify {...props} />} /> */}
        <Route path="/syncronization" render={(props) => <Syncronization {...props} />} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/more/tools" render={(props) => <MoreTools {...props} />} />
        <Route path="/upload/app/state" render={(props) => <UploadAppState {...props} />} />
        <Route path="/upload/app/sync" render={(props) => <UploadSyncState {...props} />} />
        <Route path="/clear/app/data/:reloaded?" render={(props) => <ClearAppData {...props} />} />
        <Route path="/customer/disabled/:id" render={(props) => <CustomerDisabled {...props} />} />
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <SharedRoutes />
      </Switch>
    </>
  );
};

export default Routes;
