import { useState, useMemo, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent ";
import NoLoanFound from "@shared/components/NoLoanFound";
// import Calculator from "@shared/components/Calculator";
import { getUniqueID, getTimestamp, printAmount, getApplyPaymentsProp, replaceNonDigits } from "@shared/functions";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
import { MdOutlineVerified } from "react-icons/md";
// import DebtorFetch from "@shared/components/DebtorFetch";
// import DebtorNotAvailable from "@shared/components/DebtorNotAvailable";
import LoanCanNotRenew from "../components/LoanCanNotRenew";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
// import DisplayModal from "@shared/components/DisplayModal";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderNewLoanForm from "@shared/components/RenderNewLoanForm";
import { printEntregaHandler, printLoanContractHandler } from "@shared/functions/print";
import RenderOldBalancePayOff from "@shared/components/RenderOldBalancePayOff";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import LoadingInspect from "@shared/components/LoadingInspect";
import { useSelector, useDispatch } from "react-redux";
import { addDebtorsReordered, selectLoanById } from "../store/slices/loans";
import { createNewLoan, selectNewLoanByDebtorId } from "../store/slices/newLoans";
import { useGetDebtorsQuery, useGetUserConfigQuery } from "../store/slices/api";
import { createPayment } from "../store/slices/payments";
import { createQueue } from "../store/slices/syncronization";
import { selectCommon } from "../store/slices/common";
// import CedulaWarning from "@shared/components/CedulaWarning";
import { Redirect, useHistory, Link } from "react-router-dom";
// import { alertError, alertSuccess } from "../store/slices/snackbar";
import { useGetOwnerConfigQuery, useGetRouteQuery, useGetUserQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import { selectCreateStatus, setCreatingData } from "../store/slices/ui";
import usePouch from "../Hooks/usePouch";
import { pouches_db } from "../config";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (calc) => {
  return +calc.amount - +calc.actanotarial - +calc.balance - +calc.adelanto - +calc.discount - +calc.paymentMora;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "", discount: "" };

const LoanCreate = () => {
  const payments_db = usePouch(pouches_db.PAYMENTS);
  const loans_db = usePouch(pouches_db.NEW_LOANS);

  const directUrl = useRef(null);
  const alert = useAlert();
  const history = useHistory();
  const { id: dUniqueId, id2: current_money_id } = useParams();
  const dispatch = useDispatch();

  const isDatacreating = useSelector(selectCreateStatus);

  const common = useSelector(selectCommon);
  const currentLoan = useSelector((state) => selectLoanById(state, current_money_id));
  const existingLoan = useSelector((state) => selectNewLoanByDebtorId(state, dUniqueId));

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  // const loans = useSelector(selectLoans);
  // const newLoans = useSelector(selectNewLoans);

  // const [oldBalance, setOldBalance] = useState({ totalBalance: 0, payoffBalance: 0, loansBalance: [] });
  const [formValues, setFormValues] = useState(initialFormValues);
  const [payment_id, setPaymentId] = useState(null);
  const [money_id, setMoneyId] = useState("n/a");
  // const [duplexNewLoan, setDuplexNewLoan] = useState(false);

  useEffect(() => {
    setPaymentId(getUniqueID(`PaymentP${currentLoan?.money_id}`));
    setMoneyId(getUniqueID(`NewLoan${common.creditor_id}`));
    //eslint-disable-next-line
  }, []);

  // const [currentDebtor, setCurrentDebtor] = useState(null);
  const [adelantoWarningAmount, setAdelantoWarningAmount] = useState(null);
  // const [balanceHighAlert, setBalanceHighAlert] = useState(false);
  // const [balanceLowAlert, setBalanceLowAlert] = useState(false);
  // const [balanceLowModal, setBalanceLowModal] = useState(false);
  // const [balanceHighModal, setBalanceHighModal] = useState(false);
  // const [debtorLoaded, setDebtorLoaded] = useState(false);

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega({ ...formValues, balance: currentLoan?.balance });
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount, formValues.paymentMora]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  // useEffect(() => {
  //   if (debtors) {
  //     setCurrentDebtor(getDebtorSelector(debtors, debtor_id));
  //     setDebtorLoaded(true);
  //   }
  //   //eslint-disable-next-line
  // }, [common, loans, debtor_id, debtors, newLoans]);

  if (isDatacreating === money_id) return <Redirect to={directUrl.current} />;

  if (isDatacreating === `creating${money_id}`) return <Loading />;

  if (!currentLoan)
    return (
      <div className="mt-2 mb-5">
        <Card raised>
          <CardContent>
            <NoLoanFound />
          </CardContent>
        </Card>
      </div>
    );

  const currentDebtor = debtors.find((x) => x.dUniqueId === dUniqueId);

  if (+common.owner_id === 2587 && (!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1))
    return (
      <Box component="div" p={1} paddingTop={8}>
        <Alert variant="outlined" severity="warning">
          <Typography variant="h6" component="h4" gutterBottom>
            cliente Perfil sin C&eacute;dula!
          </Typography>
          <Typography variant="body1" component="p">
            Es requerido modificar el perfil de este cliente con su c&eacute;dula para poder hacer la entrega!
          </Typography>

          <Box paddingTop={3}>
            <Link to={`/debtor/edit/${currentDebtor.dUniqueId}`}>
              <Typography variant="body1">Modificar Perfil de cliente</Typography>
            </Link>
          </Box>
        </Alert>
      </Box>
    );

  if (!currentDebtor || !debtors || !ownerConfig || !userConfig || !route || !user)
    return (
      <LoadingInspect
        data={[
          {
            loaded: currentDebtor && Object.keys(currentDebtor).length > 0,
            label: "Current Debtor",
          },
          {
            loaded: debtors && debtors.length >= 0,
            label: "Debtors",
          },
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "OwnerConfig",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "Route",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "User Config",
          },
        ]}
      />
    );

  const renWeeksRestric = +currentDebtor.dRenWeeks || +route.renWeeks;

  if (
    (+currentLoan.npayments - +currentLoan.completed > renWeeksRestric && renWeeksRestric > 0) ||
    +renWeeksRestric === -1
  )
    return <LoanCanNotRenew loan={currentLoan} restriction={renWeeksRestric} />;

  // if (!currentDebtor) return <DebtorNotAvailable />;

  // const handlePayoffBalanceToggle = (money_id, toggle) => {
  //   const { loansBalance, payoffBalance: payOff } = oldBalance;
  //   const index = loansBalance.findIndex((loan) => loan.money_id === money_id);
  //   const balanceLoan = loansBalance[index];
  //   const newBalance = (balanceLoan.isChecked = !toggle);
  //   const payoffBalance = toggle ? payOff - balanceLoan.balance : payOff + balanceLoan.balance;
  //   setOldBalance({ ...oldBalance, payoffBalance, ...newBalance });
  // };

  const initialValues = {
    sleepDelay: true,
    user_id: common.user_id,
    debtor_id: currentLoan.debtor_id,
    dUniqueId: currentDebtor.dUniqueId,
    name: currentLoan.name,
    cedula: currentDebtor?.cedula,
    percentage: currentDebtor.percentage || route.rPerc || "",
    npayments: currentDebtor.npayments || route.rWeeks || "",
    amount: currentLoan.amount,
    actanotarial: currentLoan.actanotarial,
    new_debtor: currentLoan.new_debtor,
    creditor_id: common.creditor_id,
    given_date: common.collect_week_date,
    start_date: common.next_week_date,
    paymentMora: 0,
    payoffBalance: currentLoan.balance,
    completed: 0,
    mora: 0,
    adelanto: 0,
    discount: 0,
    type: 7,
    confirmNewLoan: false,
  };

  const submithandler = (data) => submitnewLoan(data);

  let button = { label: "Guardar Prestamo", variant: "contained", color: "primary" };
  if (ownerConfig.digitalContract === "1") {
    // || ownerConfig.loanIdentification === "1"
    button = {
      icon: <MdOutlineVerified />,
      label: "Guardar Prestamo y Continuar!",
      color: "primary",
      variant: "outlined",
    };
  }

  const submitnewLoan = async (data) => {
    if ([837, 933].includes(+data.creditor_id) && +data.npayments !== 10) {
      alert.error("Solo prestamos a 10 Cuotas permitido!", { timeout: 7000, position: "top center" });
      return;
    }

    dispatch(setCreatingData({ data_id: `creating${dUniqueId}` }));
    if (entrega < 1 || data.amount < currentLoan.balance) {
      alert.error("Cantidad a entregar invalida!", { timeout: 7000, position: "top center" });
      // dispatch(alertError({ duration: 7000, message: "Cantidad a entregar es Invalida!" }));
      return;
    }

    if (data.actanotarial > (30 * data.amount) / 100) {
      alert.error("Actanotarial muy alta!", { timeout: 7000, position: "top center" });
      // dispatch(alertError({ duration: 7000, message: "Acta Notarial muy alta!" }));

      return;
    }

    // const configAlertPercentage = 30;
    // if (oldBalance.totalBalance > 0) {
    //   const balanceCount = oldBalance.loansBalance.length;
    //   for (let i = 0; i < balanceCount; i++) {
    //     const { npayments, wPayment, balance, isChecked } = oldBalance.loansBalance[i];
    //     const configAlertBalance = (wPayment * npayments * configAlertPercentage) / 100;
    //     if (balance > configAlertBalance && isChecked && !balanceHighAlert) {
    //       setBalanceHighModal(true);
    //       setBalanceHighAlert(true);
    //       return;
    //     }

    //     if (balance <= configAlertBalance && !isChecked && !balanceLowAlert) {
    //       setBalanceLowModal(true);
    //       setBalanceLowAlert(true);
    //       return;
    //     }
    //   }
    // }

    if (+data.adelanto !== 0 && +data.adelanto !== adelantoWarningAmount) {
      setAdelantoWarningAmount(data.adelanto);
      return;
    }

    const timestamp = getTimestamp();
    const mUniqueId = timestamp + data.debtor_id;

    const balanceData = { ...data, money_id, currentLoan };

    const newData = {
      ...balanceData,
      wPayment: monto,
      mUniqueId,
      creditor_id: common.creditor_id,
      collect_date: common.collect_date,
    };
    const newLoanUpload = { ...newData, currentLoan: undefined };

    const syncProps = {
      data_id: money_id,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Date.now(),
      endPoint: "/bgsync/cobro/loan/create",
      syncPriority: 1,
      syncType: "createLoan",
      syncAmount: data.amount,
      syncTitle: "Prestamo Registrado",
      syncName: `Cliente > ${data.name}`,
      requiredData: true,
    };

    const additionalData = {
      paymentType: "cash",
      paymentAmount: currentLoan.balance,
      paymentMora: data.paymentMora,
      payoffLoanId: money_id,
      payment_date: common.collect_week_date,
    };

    const recData = getApplyPaymentsProp({ ...data, ...additionalData }, currentLoan, common, payment_id);

    // Permanently disabled option to take picture for new loan.
    // if (ownerConfig.loanIdentification === "1") {
    //   if (+additionalData.paymentAmount + +additionalData.paymentMora > 0) {
    //     localStorage.setItem("recData", JSON.stringify(recData));
    //   }
    //   localStorage.setItem("newData", JSON.stringify(newLoanUpload));
    //   localStorage.setItem("syncProps", JSON.stringify(syncProps));

    //   history.replace(`/camera/permission/${money_id}`);
    //   // history.replace(`/renewal/upload/${money_id}`);
    // } else

    if (ownerConfig.digitalContract === "1") {
      directUrl.current = `/loan/signature/${money_id}`;

      if (+additionalData.paymentAmount + +additionalData.paymentMora > 0) {
        const putResult = await payments_db.putDoc({
          _id: recData.collected.pUniqueId,
          ...recData.collected,
          creditor_id: common.creditor_id,
          collect_date: common.collect_date,
          eps_payments_state: localStorage.getItem("eps_payments_state"),
        });

        const newPaymentPostData = { ...recData.sync.postData, doc_rev: putResult?.rev };
        dispatch(createQueue({ ...recData.sync, postData: newPaymentPostData }));

        dispatch(createPayment(recData.collected));
      }
      // dispatch(applyPayment(recData.applyLoan));

      // if (ownerConfig.autoPrintPayment === "1") {
      //   printReceiptHandler(
      //     { ...currentLoan, ...recData.collected },
      //     { ...route, ...ownerConfig, ...common, ...userConfig }
      //   );
      // }

      const putResult = await loans_db.putDoc({
        _id: mUniqueId,
        ...newData,
        eps_loans_state: localStorage.getItem("eps_loans_state"),
      });
      dispatch(createQueue({ postData: { ...newLoanUpload, doc_rev: putResult?.rev }, syncProps }));
      dispatch(createNewLoan(newData));
      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      alert.success("Pago Aplicado!", { timeout: 3500, position: "top center" });
      alert.success("Prestamo Guardado!", { position: "top center" });

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      // history.replace(`/loan/signature/${money_id}`);
    } else {
      directUrl.current = "/entregas";

      const putResult = await payments_db.putDoc({
        _id: recData.collected.pUniqueId,
        ...recData.collected,
        creditor_id: common.creditor_id,
        collect_date: common.collect_date,
        eps_payments_state: localStorage.getItem("eps_payments_state"),
      });

      const newPaymentPostData = { ...recData.sync.postData, doc_rev: putResult?.rev };
      dispatch(createQueue({ ...recData.sync, postData: newPaymentPostData }));

      dispatch(createPayment(recData.collected));

      // dispatch(applyPayment(recData.applyLoan));

      // if (ownerConfig.autoPrintPayment === "1") {
      //   printReceiptHandler(
      //     { ...currentLoan, ...recData.collected },
      //     { ...route, ...ownerConfig, ...common, ...userConfig }
      //   );
      // }

      const putResult2 = await loans_db.putDoc({
        _id: mUniqueId,
        ...newData,
        eps_loans_state: localStorage.getItem("eps_loans_state"),
      });
      dispatch(createQueue({ postData: { ...newLoanUpload, doc_rev: putResult2?.rev }, syncProps }));
      dispatch(createNewLoan(newData));
      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      alert.success("Pago Aplicado!", { timeout: 3500, position: "top center" });
      alert.success("Prestamo Guardado!", { position: "top center" });

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      // history.replace(`/entregas`);
    }
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentLoan}>
        <LoanDetailMenu loan={currentLoan} />
      </DebtorNameHeader>

      {/* {(!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1) && (
        <CedulaWarning
          debtor={currentDebtor}
          updateDebtorCedula={updateDebtorCedula}
          mutate={mutate}
          redirect={`/loan/renewal/${currentDebtor.dUniqueId}/${money_id}`}
        />
      )} */}

      <Box component="div" p={1}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6" component="h4">
              Renovar Prestamo!
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <Calculator /> */}
          </Grid>
        </Grid>

        <Box paddingBottom={1}>
          <Divider />
        </Box>

        {existingLoan ? (
          <div className="mt-2 mb-5">
            <Box paddingBottom={1}>
              <Grow direction="up" in>
                <Alert variant="outlined" severity="error">
                  <Typography variant="body1" component="p" className="font-weight-bold">
                    Este cliente, ya tiene un nuevo prestamo registrado!
                  </Typography>
                </Alert>
              </Grow>
            </Box>
          </div>
        ) : (
          <>
            <Box div="div" p={1}>
              <RenderOldBalancePayOff oldBalance={currentLoan.balance} />
              {/* <RenderOldBalancePayOff oldBalance={oldBalance} handlePayoffBalanceToggle={handlePayoffBalanceToggle} /> */}
            </Box>

            <Box div="div" p={1}>
              <RenderNewLoanCalculation data={{ monto, entrega: entrega, profit }} />
            </Box>

            <Box div="div" p={1}>
              <RenderNewLoanForm
                setFormValues={setFormValues}
                initialValues={initialValues}
                submithandler={submithandler}
                button={button}>
                {adelantoWarningAmount && (
                  <Box paddingBottom={1}>
                    <Grow direction="up" in>
                      <Alert variant="outlined" severity="warning">
                        <Typography variant="body1" component="p">
                          Esta aplicado <b>un pago de adelanto de {printAmount(adelantoWarningAmount)}</b>, si quiere
                          aplicar le este adelanto de {printAmount(adelantoWarningAmount)} trate nuevamente!
                        </Typography>
                      </Alert>
                    </Grow>
                  </Box>
                )}
              </RenderNewLoanForm>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default LoanCreate;
