import { useState, useEffect } from "react";
import { useParams } from "react-router";
import RenderDebtorForm from "@shared/components/RenderDebtorForm";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
// import { Context } from "@shared/context/DataContext";
import Loading from "@shared/components/Loading";
import { replaceNonDigits, getUniqueID } from "@shared/functions";
// import { toast } from "react-toastify";
import { selectCommon } from "../store/slices/common";
import { createNewDebtor } from "../store/slices/newDebtors";
import { createQueue } from "../store/slices/syncronization";
import { getTimestamp } from "@shared/functions";
import { useSelector, useDispatch } from "react-redux";
import RenderSwitchButton from "@shared/components/RenderSwitchButton";
import { useHistory } from "react-router-dom";
// import { alertSuccess } from "../store/slices/snackbar";
import { useGetDebtorsQuery } from "../store/slices/api";
import { useGetOwnerConfigQuery } from "../store/slices/api"; //useCreateDebtorMutation
import { useAlert } from "react-alert";
import { addMethod, mixed } from "@nodes/yup";
import { validateCedula } from "@shared/functions";
import { UseCedulasNames } from "@shared/server/fetcher";
import usePouch from "../Hooks/usePouch";
import { pouches_db } from "../config";

const DebtorCreate = () => {
  const debtors_db = usePouch(pouches_db.NEW_DEBTORS);
  const { ced: urlCedula } = useParams();
  addMethod(mixed, "validateCedula", validateCedula);

  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const common = useSelector(selectCommon);
  const [celData, setCelData] = useState({ name: "", phone: "", occupation: "", address: "", work_address: "" });

  const { data: cachedCelData } = UseCedulasNames(urlCedula ? urlCedula.substring(0, 1) : null);

  // let debtorAlreadyExistInRoute = false;
  // let wasCedulaNameChanged = false;

  // const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState(true);

  useEffect(() => {
    if (!cachedCelData) return;
    if (cachedCelData[urlCedula]) {
      setCelData({
        name: cachedCelData[urlCedula].name,
        phone: cachedCelData[urlCedula].phone,
        occupation: cachedCelData[urlCedula].occupation,
        address: cachedCelData[urlCedula].address,
        work_address: cachedCelData[urlCedula].work_address,
      });
    }
  }, [cachedCelData]);

  // const [createDebtor] = useCreateDebtorMutation();
  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);

  const initialValues = {
    user_id: common.user_id,
    owner_id: common.owner_id,
    creditor_id: common.creditor_id,
    collect_date: common.collect_date,
    name: celData.name ?? "",
    phone: celData.phone ?? "",
    address: celData.address ?? "",
    occupation: celData.occupation ?? "",
    work_address: celData.work_address ?? "",
    cedula: urlCedula ?? "",
    phone2: "",
    nickname: "",
    display_name: "",
    birthdate: "",
    next_to: "",
    cosigner: "",
    note: "",
    npayments: "",
    percentage: "",
    amount: "",
    actanotarial: "",
    new_debtor: true,
    created_date: common.today_date,
    // zone_id: misc.id,
  };

  if (!debtors || !ownerConfig) return <Loading />;

  const button = { icon: <PersonAddIcon />, label: "Registrar Cliente", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    const numericCedula = replaceNonDigits(data.cedula);
    const cedulas = debtors.map((a) => a.cedula);

    const timestamp = getTimestamp();
    const debtor_id = getUniqueID(`Debtor${data.creditor_id}`);

    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;
    const syncProps = {
      data_id: debtor_id,
      syncronization_id,
      queueTime: Date.now(),
      endPoint: "/bgsync/cobro/debtor/create",
      syncType: "createDebtor",
      syncAmount: "",
      syncTitle: "Nuevo Cliente Registrado",
      syncName: `Cliente > ${data.name}`,
      requiredData: true,
    };

    const debtorSearch = `${data.name} ${data.nickname} ${data.cedula} ${data.phone} ${data.phone2}`;

    const dUniqueId = timestamp + data.creditor_id;
    const newData = {
      ...data,
      timestamp,
      debtor_id,
      dUniqueId,
    };

    // if (ownerConfig.allowDuplicateDebtor === "0") {
    //   await cobroCreateDebtorUnique(data, history.replace);
    // } else {
    //   createDebtor({ postData: { ...newData, debtorSearch, syncronization_id }, syncProps });
    // }

    if (cachedCelData && cachedCelData[urlCedula] && urlCedula === numericCedula) {
      if (data.phone !== celData.phone && data.phone.length > 0) {
        const syncProps = {
          syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
          queueTime: Date.now(),
          endPoint: "/bgsync/cobro/update/data",
          syncPriority: 6,
          syncType: "cedulaPhone",
          syncAmount: "",
          syncTitle: "Telefono de Cedula Actualizado",
          syncName: `Telefono > ${data.phone}`,
        };

        dispatch(createQueue({ postData: { cedula: data.cedula, phone: data.phone }, syncProps }));
      }

      if (data.address !== celData.address && data.address.length > 0) {
        const syncProps = {
          syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
          queueTime: Date.now(),
          endPoint: "/bgsync/cobro/update/data",
          syncPriority: 6,
          syncType: "cedulaAddress",
          syncAmount: "",
          syncTitle: "Direcion de Cedula Actualizado",
          syncName: `Direcion > ${data.address}`,
        };

        dispatch(createQueue({ postData: { cedula: data.cedula, address: data.address }, syncProps }));
      }

      if (data.work_address !== celData.work_address && data.work_address.length > 0) {
        const syncProps = {
          syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
          queueTime: Date.now(),
          endPoint: "/bgsync/cobro/update/data",
          syncPriority: 6,
          syncType: "cedulaWorkAddress",
          syncAmount: "",
          syncTitle: "Direcion de Cedula Actualizado",
          syncName: `Dir Trabajo > ${data.work_address}`,
        };

        dispatch(createQueue({ postData: { cedula: data.cedula, work_address: data.work_address }, syncProps }));
      }

      if (data.occupation !== celData.occupation && data.occupation.length > 0) {
        const syncProps = {
          syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
          queueTime: Date.now(),
          endPoint: "/bgsync/cobro/update/data",
          syncPriority: 6,
          syncType: "cedulaOccupation",
          syncAmount: "",
          syncTitle: "Occupacion de Cedula Actualizado",
          syncName: `Occupacion > ${data.occupation}`,
        };

        dispatch(createQueue({ postData: { cedula: data.cedula, occupation: data.occupation }, syncProps }));
      }

      if (data.name !== celData.name && data.name.length > 0) {
        const syncProps = {
          syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
          queueTime: Date.now(),
          endPoint: "/bgsync/cobro/update/data",
          syncPriority: 6,
          syncType: "cedulaName",
          syncAmount: "",
          syncTitle: "Nombre de Cedula Actualizado",
          syncName: `Nombre > ${data.name}`,
        };

        dispatch(createQueue({ postData: { cedula: data.cedula, name: data.name }, syncProps }));
      }
    }

    if (cedulas.includes(numericCedula) && numericCedula !== "00000000000") {
      const debtor = debtors.find((i) => i.cedula === numericCedula);

      if (uploadImage) {
        history.replace(`/debtor/upload/${debtor.dUniqueId}`);
      } else {
        //Cedula is not needed for redirect page because at this point Debtor has been already credit Checked.
        history.replace(`/redirect/page/${debtor.dUniqueId}/0`);
      }
    } else {
      // createDebtor({ postData: { ...newData, debtorSearch, syncronization_id }, syncProps });
      // await debtors_db.putDoc({ _id: dUniqueId, ...newData, debtorSearch, syncronization_id });
      dispatch(createNewDebtor({ ...newData, debtorSearch, syncronization_id }));

      debtors_db.putDoc({
        _id: dUniqueId,
        ...newData,
        debtorSearch,
        syncronization_id,
        eps_debtors_state: localStorage.getItem("eps_debtors_state"),
      });

      alert.success("Cliente registrado!");

      if (uploadImage) {
        history.replace(`/debtor/upload/${dUniqueId}`);
      } else {
        history.replace(`/redirect/page/${dUniqueId}/0`);
      }
    }
  };

  return (
    <div className="container mt-3 mb-5">
      {!cachedCelData && (
        <div id="user-panel" style={{ display: "inline-flex" }}>
          <CircularProgress size="1em" />
          <Typography variant="body2" className="pl-1 text-primary" gutterBottom>
            Descargando informaci&oacute;n de la Cedula!
          </Typography>
        </div>
      )}

      <RenderDebtorForm
        title="Registrar nuevo Cliente!"
        initialValues={initialValues}
        submithandler={submithandler}
        button={button}
      />

      <RenderSwitchButton
        name="uploadDebtorImage"
        label="Subir foto de Identificacion!!"
        value={uploadImage}
        setHandler={setUploadImage}
      />
    </div>
  );
};

export default DebtorCreate;
