import { useState, useEffect } from "react";
import PendingDataWarning from "@shared/components/PendingDataWarning";
import AppUpdateModal from "../components/AppUpdateModal";
import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Redirect, Link, useHistory } from "react-router-dom";
// import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import * as mobileDetect from "react-device-detect";
import RenderLoginForm from "@shared/components/RenderLoginForm";
// import OldDomainWarning from "@shared/components/OldDomainWarning";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "../store/slices/auth";
// import { selectAuth } from "../store/slices/auth";
import { setDevice } from "../store/slices/miscData";
import { selectQueues } from "../store/slices/syncronization";
import { setDisplayNav } from "../store/slices/ui";
import { useAuth } from "../Hooks/useAuth";
import usePouch from "../Hooks/usePouch";
import usePouchQueues from "../Hooks/usePouchQueues";
import api from "@shared/server/api";
import { getLocalToken } from "@shared/functions";
import { localPouchPrefix, pouches_db } from "../config"; //localStorageCommon
import { selectCommon, setCommon } from "../store/slices/common";
// import PouchDB from "pouchdb-browser";

const Login = () => {
  const history = useHistory();
  const payments_db = usePouch(pouches_db.PAYMENTS);
  const gastos_db = usePouch(pouches_db.GASTOS);
  const loans_db = usePouch(pouches_db.LOANS);
  const debtors_db = usePouch(pouches_db.DEBTORS);
  const debtorsReorder_db = usePouch(pouches_db.DEBTORS_REORDER);
  const newLoans_db = usePouch(pouches_db.NEW_LOANS);
  const newDebtors_db = usePouch(pouches_db.NEW_DEBTORS);
  const conflicts_db = usePouch(pouches_db.SYNC_CONFLICTS);
  const offline_db = usePouch(pouches_db.OFFLINE_DATA);
  const queues_db = usePouch(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);

  const [errors, setErrors] = useState(null);
  // const [errorDisplay, setErrorDisplay] = useState(null);
  const auth = useAuth();
  const queues = useSelector(selectQueues);
  const common = useSelector(selectCommon);
  const dispatch = useDispatch();
  const [appUpdate, setAppUpdate] = useState(false);
  const { browserName, fullBrowserVersion, mobileVendor, mobileModel, osVersion, getUA } = mobileDetect;
  const filteredQueues = queues.filter((q) => q.requiredData === true);

  const getDeviceSpecs = () => {
    let displayMode = "Browser";
    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "PWA Standalone";
    }

    return {
      displayMode,
      appVersion: import.meta.env.VITE_VERSION,
      foceAppUpdate: import.meta.env.VITE_foceAppUpdateNumber,
      browserName,
      fullBrowserVersion,
      mobileVendor,
      mobileModel,
      osVersion,
      getUA,
    };
  };

  useEffect(() => {
    dispatch(resetAuth());
    dispatch(setDisplayNav({ DisplayNav: false }));
    dispatch(setDevice(getDeviceSpecs()));
  }, []);

  const deleteExpiredDocs = async () => {
    const docs = await offline_db.getAllDocs();

    const now = Date.now();
    const expired = docs
      .filter((doc) => doc.meta.expiresAt && doc.meta.expiresAt < now)
      .map((doc) => ({
        _id: doc._id,
        _rev: doc._rev,
        _deleted: true,
      }));

    if (expired.length > 0) {
      await offline_db.bulkDocs(expired);
    }

    await offline_db.compact();
  };

  const emptySyncIfQueueEmpty = async () => {
    const queues = await queues_db.getAllDocs();

    if (queues.length === 0) {
      console.log("Syncronization DB is now empty.");
      await history_db.deleteDatabase();
      await queues_db.deleteDatabase();
    } else {
      console.log("Syncronization DB is kept because it still has data.");
    }
  };

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     dispatch(resetAuth());
  //   }, 5000);

  //   return () => clearTimeout(interval);
  // }, [auth.error]);

  if (common?.cuadre_id && auth.data.jwt !== null) return <Redirect to="/swr/fetcher" />;

  const submithandler = async (data) => {
    try {
      const token = getLocalToken();

      // const device_db = new PouchDB(`${import.meta.env.VITE_COUCH_URL}/queues-${token}`);

      // try {
      //   const compact = await device_db.compact();
      //   console.log({ compact });
      // } catch (err) {
      //   console.log(err);
      // }

      const ipAddress = "1.0.0.27";
      // const rData = await auth.login({ ...data, token });
      const { data: rData } = await api.post("/cobro/login/v2", {
        data: { ...data, token, ipAddress },
        foceAppUpdateNumber: import.meta.env.VITE_foceAppUpdateNumber,
      });

      // if (!rData) return;

      if (rData.action === "routeDisabled") {
        history.push(`/customer/disabled/${rData.customer.customer_id}`);
        // setErrorDisplay("Su cuenta esta desabilitada por falta de pago!");
        return;
      }

      const { user_id, creditor_id, owner_id } = rData.user;

      const { data: dbCommon } = await api.post("/cobro/cuadre/init", {
        creditor_id,
        owner_id,
        user_id,
        token,
        env: import.meta.env.VITE_ENV,
        appVersion: import.meta.env.VITE_VERSION,
      });

      const now = Date.now();
      const meta = { expiresAt: now + 60 * 60 * 24 * 30 * 1000 };

      // const localCommon = await offline_db.getDocById("common");

      const lastUsedCuadreId = localStorage.getItem("localCommonId");

      if (lastUsedCuadreId !== dbCommon.cuadre_id) {
        // localStorage.setItem(localStorageCommon, JSON.stringify(dbCommon));

        localStorage.setItem("eps_debtors_state", "0");

        localStorage.setItem("eps_loans_state", "0");

        localStorage.setItem("eps_payments_state", "0");

        const prefix = `w${dbCommon.owner_id}_r${dbCommon.creditor_id}_${dbCommon.collect_date}_c${dbCommon.cuadre_id}`;
        localStorage.setItem(localPouchPrefix, prefix);

        await offline_db.putSert({ _id: "common", ...dbCommon, meta });

        localStorage.setItem("localCommonId", dbCommon.cuadre_id);

        await loans_db.deleteDatabase();
        await debtors_db.deleteDatabase();

        await payments_db.deleteDatabase();
        await gastos_db.deleteDatabase();
        await newDebtors_db.deleteDatabase();
        await newLoans_db.deleteDatabase();

        await conflicts_db.deleteDatabase();
        await emptySyncIfQueueEmpty();

        // Populate pouch DB with Debtors.
        const { data: debtors } = await api.get(`/debtors/${creditor_id}/${user_id}`);
        debtors.forEach(function (row) {
          row._id = row.debtor_id;
        });
        await debtors_db.bulkDocs(debtors);

        // Populate pouch DB with loans.
        //FIXME: When loans are fetched it will make cuadre init for common to fetch  a new creditor_id.
        // const { data: loansResult } = await api.get(`/cobro/loansv3/${user_id}/${creditor_id}`);
        // loansResult.loans.forEach(function (row2) {
        //   row2._id = row2.money_id;
        // });
        // await loans_db.bulkDocs(loansResult.loans);

        // Populate pouch DB with Debtors Reoder.
        // loansResult.debtorsReorded.forEach(function (row3) {
        //   row3._id = row3.dUniqueId;
        // });
        // await debtorsReorder_db.bulkDocs(loansResult.debtorsReorded);
      }

      // Store pouch DB USer.
      const pouch_user_id = `w${user_id}`;
      const pouch_user = await offline_db.getDocById(pouch_user_id);

      if (pouch_user) {
        pouch_user.meta.expiresAt = meta.expiresAt;
        await offline_db.putDoc(pouch_user);
      } else {
        const { data: db_user } = await api.get(`/user/${user_id}`);
        await offline_db.putDoc({ _id: pouch_user_id, ...db_user, meta });
      }

      // Store pouch DB Route.
      const pouch_route_id = `r${creditor_id}`;
      const pouch_route = await offline_db.getDocById(pouch_route_id);
      if (pouch_route) {
        pouch_route.meta.expiresAt = meta.expiresAt;
        await offline_db.putDoc(pouch_route);
      } else {
        const { data: db_route } = await api.get(`/route/info/${creditor_id}/${user_id}`);
        await offline_db.putDoc({ _id: pouch_route_id, ...db_route, meta });
      }

      // Store pouch DB owner Config.
      const pouch_owner_config_id = `oc${owner_id}`;
      const pouch_owner_config = await offline_db.getDocById(pouch_owner_config_id);
      if (pouch_owner_config) {
        pouch_owner_config.meta.expiresAt = meta.expiresAt;
        await offline_db.putDoc(pouch_owner_config);
      } else {
        const { data: db_owner_configs } = await api.get(`/owner/configv2/${owner_id}`);
        await offline_db.putDoc({ _id: pouch_owner_config_id, ...db_owner_configs, meta });
      }

      // Store pouch DB USer Config.
      const pouch_user_config_id = `uc${user_id}`;
      const pouch_user_config = await offline_db.getDocById(pouch_user_config_id);
      if (pouch_user_config) {
        pouch_user_config.meta.expiresAt = meta.expiresAt;
        await offline_db.putDoc(pouch_user_config);
      } else {
        const { data: db_user_configs } = await api.get(`/user/configv2/${user_id}`);
        await offline_db.putDoc({ _id: pouch_user_config_id, ...db_user_configs, meta });
      }

      // Store pouch DB Permissions.
      const pouch_permissions_id = `p${user_id}${creditor_id}`;
      const pouch_permissions = await offline_db.getDocById(pouch_permissions_id);
      if (pouch_permissions) {
        pouch_permissions.meta.expiresAt = meta.expiresAt;
        await offline_db.putDoc(pouch_permissions);
      } else {
        const { data: db_permissions } = await api.get(`/user/route/permissions/${user_id}/${creditor_id}`);
        await offline_db.putDoc({ _id: pouch_permissions_id, ...db_permissions, meta });
      }

      await deleteExpiredDocs();

      auth.setRememberData({ ...data, ...rData.user });

      auth.setAuthData(rData);

      dispatch(setCommon(dbCommon));

      // if (dbCommon && rData && auth.data && auth.isLoading === false) {
      //   dispatch(authThunk({ data }));
      // }
    } catch (error) {
      console.log(error);

      if (error.response) {
        setErrors(error.response.data.message);
      } else {
        setErrors(error.message);
      }
    }
  };

  return (
    // <OldDomainWarning url="https://beta-cobro.wprestamos.com" label="beta-cobro.wprestamos.com" />
    <Container fixed maxWidth="sm">
      {appUpdate && <AppUpdateModal />}

      {/* {authDiscontinued.commonError && (
        <div className="alert alert-danger mt-3">
          <Typography variant="subtitle2" component="p">
            {authDiscontinued.commonError}
          </Typography>
        </div>
      )} */}

      {filteredQueues.length > 0 ? (
        <PendingDataWarning pendingData={filteredQueues} />
      ) : (
        <>
          {/* {errorDisplay && (
            <div className="alert alert-danger mt-3">
              <Typography variant="subtitle2" component="p">
                {errorDisplay}
              </Typography>
            </div>
          )} */}

          {auth.errors && (
            <div className="alert alert-danger mt-3">
              <Typography variant="subtitle2" component="p">
                {auth.errors}
              </Typography>
            </div>
          )}

          {errors && (
            <div className="alert alert-danger mt-3">
              <Typography variant="subtitle2" component="p">
                {errors}
              </Typography>
            </div>
          )}

          <RenderLoginForm submithandler={submithandler} />
        </>
      )}

      {/* <div style={{ marginTop: 160, marginBottom: 50 }} className="text-center">
        <Link to="/clear/app/data" className="h6">
          <span className="text-danger">Reiniciar Aplicaci&oacute;n (Borrar Todo)</span>
        </Link>
      </div> */}
    </Container>
  );
};

export default Login;
