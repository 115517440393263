import { useEffect, useState } from "react";
import PouchDB from "pouchdb-browser";

export const syncDatabases = async (localDb, remoteDb) => {
  try {
    const db = new PouchDB(localDb);
    const db2 = new PouchDB(`${import.meta.env.VITE_COUCH_URL}/${remoteDb}`);

    return await PouchDB.sync(db, db2);
  } catch (err) {
    console.log("Error while trying to sync DB: ", err);
    throw new Error(err);
  }
};

export const checkSyncStatus = async (localDb, remoteDb) => {
  try {
    const db = new PouchDB(localDb);
    const db2 = new PouchDB(`${import.meta.env.VITE_COUCH_URL}/${remoteDb}`);

    const localInfo = await db.info();
    const remoteInfo = await db2.info();

    // console.log(localInfo);
    // console.log(remoteInfo);

    const update_seq_items = remoteInfo.update_seq.split("-");

    if (+localInfo.update_seq === +update_seq_items[0]) {
      // console.log("Databases are in sync");
      return true; // No pending changes
    } else {
      // console.log("Pending changes detected");
      return false; // Changes need to be replicated
    }
  } catch (error) {
    console.error("Error checking sync status:", error);
    throw new Error(error);
  }
};

const usePouchSync = (localDb, remoteDb = null, options = {}) => {
  const [syncState, setSyncState] = useState({
    syncActive: false,
    lastError: null,
    replicationStatus: null,
  });

  useEffect(() => {
    if (!remoteDb) return;

    let pouchDbSyncActiveEvent = false;
    let pouchDbSyncChangeEvent = false;

    const repHandler = PouchDB.sync(localDb, `${import.meta.env.VITE_COUCH_URL}/${remoteDb}`, {
      live: true,
      retry: true,
      ...options,
    });

    // Update sync state
    // setSyncState((prev) => ({ ...prev, syncActive: true }));

    // Event listeners
    repHandler
      .on("active", () => {
        pouchDbSyncActiveEvent = true;
        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: "Replication active.",
        // }));
      })
      .on("change", (info) => {
        pouchDbSyncChangeEvent = true;
        // console.log(`Change detected on ${localDb}: ${JSON.stringify(info)}`);

        // console.log(info.change.docs[0]._id);
        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: `Change detected: ${JSON.stringify(info)}`,
        // }));
      })
      .on("paused", (err) => {
        if (pouchDbSyncActiveEvent == true && pouchDbSyncChangeEvent == false) {
          // Gotcha! Syncing with remote DB not happening!
          console.log("Gotcha! Syncing with remote DB not happening!");
        } else {
          // Everything's ok. Syncing with remote DB happening normally.
        }

        pouchDbSyncActiveEvent = false;
        pouchDbSyncChangeEvent = false;

        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: "Replication paused.",
        //   lastError: err || null,
        // }));
      })

      .on("error", (err) => {
        console.error("Replication error:", err);
        setSyncState((prev) => ({
          ...prev,
          syncActive: false,
          lastError: err,
        }));
      });

    // Cleanup on unmount
    return () => {
      repHandler.cancel();
      setSyncState((prev) => ({
        ...prev,
        syncActive: false,
        replicationStatus: "Replication stopped.",
      }));
    };
  }, [localDb, remoteDb]);

  return syncState;
};

export default usePouchSync;
