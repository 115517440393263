import PouchDB from "pouchdb-browser";
import { localPouchPrefix } from "../config";

export default function usePouch(dbName) {
  const getDB = () => {
    return new PouchDB(dbName);
  };

  const getAllDocIds = async (options) => {
    try {
      const res = await getDB().allDocs({ include_docs: false, ...options });
      return res.rows.filter((x) => !x.deleted);
    } catch (err) {
      console.log("Error fetching docs ID", err);
      return [];
    }
  };

  const getAllDocs = async (options) => {
    try {
      const result = await getDB().allDocs({ include_docs: true, ...options });
      const docs = result.rows.map((row) => row.doc);

      if (options?.include_deleted) return docs;
      return docs.filter((x) => !x.deleted);
    } catch (error) {
      console.error("Error fetching all docs:", error);
      return [];
    }
  };

  const getDocById = async (docId, options = {}) => {
    try {
      return await getDB().get(docId, options);
    } catch (err) {
      // console.log("Error while fetching single Doc:", err);
      return null;
    }
  };

  const putDoc = async (doc, options = {}) => {
    try {
      //TODO: Temporary adding this prop for debugging purpose.
      const prefix = localStorage.getItem(localPouchPrefix);

      if (prefix) {
        doc.prefix = prefix;
      }

      const putRes = await getDB().put(doc, options);

      return putRes;
    } catch (err) {
      console.log("Error in putDoc function:", err);
      throw new Error(err);
    }
  };

  const putSert = async (data, options = {}) => {
    try {
      const doc = await getDocById(data._id);
      if (doc) return await getDB().put({ ...doc, ...data }, options);

      return await getDB().put(data, options);
    } catch (err) {
      console.log("Error in putSert function", err);
      throw new Error(err);
    }
  };

  const bulkDocs = async (docs, options = {}) => {
    try {
      const result = await getDB().bulkDocs(docs, options);
      return result;
    } catch (err) {
      console.log("Error in bulkDocs function", err);
      return err;
    }
  };

  const deleteDoc = async (docId) => {
    try {
      const doc = await getDB().get(docId);

      doc.deleted = true;
      const result = await getDB().put(doc);
      return result;
    } catch (err) {
      console.log("Error while deleting doc:", err);
      throw new Error(err);
    }
  };

  const getDatabaseInfo = async () => {
    try {
      return await getDB().info();
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const compact = async () => {
    try {
      return await getDB().compact();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDatabase = async () => {
    try {
      return await getDB().destroy();
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  return {
    getDocById,
    getAllDocIds,
    getAllDocs,
    putDoc,
    putSert,
    compact,
    deleteDoc,
    bulkDocs,
    getDatabaseInfo,
    deleteDatabase,
  };
}
