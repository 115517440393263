// Data key name for Persisting App State.
export const localStorageKey = "wbo-cobro-data";
export const localStorageRememberUser = "wbo-cobro-remeberUser";
export const localStorageToken = "wbo-cobro-tokenV2";
export const localStorageSync = "wbo-cobro-sync";
export const localStorageAuth = "cobroAuthToken";
export const localStorageCurrentRoute = "cobro-ummYearsggdjjj";
export const currentWeekRouteDate = "currentWeekRouteDate";
export const localStoragePrintApp = "printApp";
export const localStorageReduxPersist = "wprestamos-cobro";

// After pouchDB
export const localStorageCommon = "localCommonv2";
export const localPouchPrefix = "syncPrefix";
export const localStorageAuthv2 = "cobro_auth";
export const pouches_db = {
  SYNC_CONFLICTS: "sync-conflicts",
  SYNC_HISTORY: "local-changes-history",
  SYNC_QUEUES: "local-changes",
  DEBTORS: "debtors",
  DEBTORS_REORDER: "debtorsReorded",
  LOANS: "loans",
  NEW_DEBTORS: "new-debtors",
  NEW_LOANS: "new-loans",
  PAYMENTS: "payments",
  GASTOS: "gastos",
  OFFLINE_DATA: "offline-data",
};
export const syncStatus = { FAILED: "failed", COMPLETED: "completed", PENDING: "pending" };

// Charts Colors
export const colorAdelantos = "#98ee8d";
export const colorAtrasos = "#3e5a3b";
export const colorRegularPayment = "#72c068";
export const colorNewLoans = "#4d88df";
export const colorActaNoctarial = "#9e85af";
export const colorGastos = "#cf7777";
export const colorXcobrar = "#aeb1af";
export const colorLoanRenewal = "#75aafa";

//This constant should be 1 on WPrestamos main & 0 on WPrestamos cobro project.
export const mainApp = 0;
export const projectName = "WPrestamos Cobro";
