// import { useState, useEffect } from "react";
import { useParams } from "react-router";
// import Loading from "@shared/components/FullLoadingScreen";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import { Context } from "@shared/context/DataContext";
// import { getNewLoansByDebtor } from "@shared/context/Selectors";
// import { getDebtorLoansBalanceSelector } from "@shared/context/Selectors"; //getImagesByDebtor
import { printAmount } from "@shared/functions";
import { FaHandHoldingUsd } from "react-icons/fa";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
// import DebtorFetch from "@shared/components/DebtorFetch";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorLoans from "@shared/components/RenderDebtorLoans";
// import RenderDebtorImages from "@shared/components/RenderDebtorImages";
import RenderDebtorProfile from "@shared/components/RenderDebtorProfile";
import DebtorMenu from "@shared/components/DebtorMenu";
import DebtorNotAvailable from "../components/DebtorNotAvailable";
import Loading from "@shared/components/Loading";
// import { useLiveQuery } from "dexie-react-hooks";
// import { dexieSyncDB } from "@shared/context/dexieDB";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";
import { selectLoansByDebtorId } from "../store/slices/loans";
import { selectNewLoanByDebtorId } from "../store/slices/newLoans";
import { useGetDebtorsQuery } from "../store/slices/api";
import { useHistory } from "react-router-dom";
import { selectNewDebtorById } from "../store/slices/newDebtors";
import useDataObject from "../Hooks/useDataObject";
import { pouches_db } from "../config";

const DebtorProfile = () => {
  const history = useHistory();
  const { id: dUniqueId } = useParams();
  const common = useSelector(selectCommon);
  const newLoan = useSelector((state) => selectNewLoanByDebtorId(state, dUniqueId));
  const loans = useSelector((state) => selectLoansByDebtorId(state, dUniqueId));
  const newDebtor = useSelector((state) => selectNewDebtorById(state, dUniqueId));

  const { data: currentPouchDebtor, isLoading: d_loading } = useDataObject(pouches_db.NEW_DEBTORS, dUniqueId);

  // const { debtorImages } = state;

  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  if (!debtors || d_loading) return <Loading />;

  const currentDebtor2 = debtors.find((x) => x.dUniqueId === dUniqueId);

  const currentDebtor = currentDebtor2 ?? newDebtor ?? currentPouchDebtor;

  if (!currentDebtor) return <DebtorNotAvailable />;

  const totalBalance = loans?.reduce((acc, obj) => {
    return obj.balance > 0 ? acc + +obj.balance : acc;
  }, 0);

  // const images = useLiveQuery(() => dexieSyncDB.debtorImages.where("debtor_id").equals(debtor_id).toArray(), []);
  // const images = getImagesByDebtor(debtorImages, debtor_id);

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentDebtor}>
        <DebtorMenu debtor={currentDebtor} />
      </DebtorNameHeader>

      {currentDebtor?.is_active === "0" && (
        <Box component="div">
          <div className="alert alert-danger">
            <Typography variant="subtitle2" component="p">
              Este cliente fue eliminado!
            </Typography>
          </div>
        </Box>
      )}

      {newLoan && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="info">
              <Typography variant="body1" component="p">
                Nuevo prestamo de {printAmount(newLoan.amount)} registrado!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      {totalBalance === 0 && !newLoan && (
        <div>
          <Box component="div">
            <div className="alert alert-primary">
              <Typography variant="subtitle2" component="p">
                Este Cliente no tiene prestamos con balance!
              </Typography>
            </div>
          </Box>
          <Box paddingBottom={2}>
            <Button
              fullWidth
              startIcon={<FaHandHoldingUsd size="1.2em" />}
              variant="outlined"
              color="default"
              onClick={() => history.push(`/redirect/page/${currentDebtor.dUniqueId}/0/${currentDebtor.cedula}`)}>
              Nuevo Prestamo!
            </Button>
          </Box>
        </div>
      )}

      <Box component="div" p={1}>
        <RenderDebtorProfile debtor={currentDebtor} />
      </Box>

      <Box component="div" p={1}>
        <RenderDebtorLoans loans={loans} />
      </Box>

      {/* <RenderDebtorImages  images={images} /> */}
    </div>
  );
};

export default DebtorProfile;
