import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button ";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import { printReceiptHandler } from "@shared/functions/print";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
// import PaymentNotFound from "../components/PaymentNotFound";
import { useSelector } from "react-redux";
import RenderBackButton from "@shared/components/RenderBackButton";
import { selectCommon } from "../store/slices/common";
import { selectPaymentById } from "../store/slices/payments";
import { selectLoanById } from "../store/slices/loans";
import { useGetOwnerConfigQuery, useGetUserConfigQuery, useGetRouteQuery, useGetUserQuery } from "../store/slices/api";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PrintIcon from "@material-ui/icons/Print";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import parse from "@nodes/html-react-parser";
// import Loading from "@shared/components/Loading";
import { makeStyles } from "@nodes/@material-ui/core/styles";
// import { selectSyncronizationById } from "../store/slices/syncronization";

const useStyles = makeStyles({
  orange: {
    color: "orange",
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
});

const PaymentReceipt = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: payment_id, id2: syncronization_id, print: autoPrint } = useParams();
  const payment = useSelector((state) => selectPaymentById(state, payment_id));
  // const sync = useSelector((state) => selectSyncronizationById(state, syncronization_id));
  const loan = useSelector((state) => selectLoanById(state, payment?.money_id));
  const common = useSelector(selectCommon);
  const [receipt, setReceipt] = useState("");

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);
  const { data: user } = useGetUserQuery(common.user_id);

  useEffect(() => {
    // if (!payment || (!sync && syncronization_id !== "undefined")) return;
    if (!payment) return;
    // localStorage.removeItem("lastPaymentId");
    setReceipt(
      printReceiptHandler(
        { ...loan, ...payment },
        {
          ...route,
          ...ownerConfig,
          ...userConfig,
          ...common,
          ...user,
          printApp: "html",
          excludeExtraInfo: true,
          routePhone: route.phone,
          routePhone2: route.phone2,
        }
      )
    );

    if (autoPrint === "1") {
      printReceiptHandler(
        { ...loan, ...payment },
        {
          ...route,
          ...ownerConfig,
          ...userConfig,
          ...common,
          ...user,
          routePhone: route.phone,
          routePhone2: route.phone2,
        }
      );
      history.replace("/dashboard");
    }
  }, [payment]);

  // if (!payment || (!sync && syncronization_id !== "undefined")) return <PaymentNotFound />;

  const handleWhatsAppPrint = () => {
    handlePrinting(payment, { printDestination: "whatsapp" });
  };

  const handlePaymentPrint = () => {
    handlePrinting(payment, { printDestination: "printer" });
  };

  const handlePrinting = (payment, option) => {
    if (option.printDestination === "whatsapp") {
      window.location = `whatsapp://send?text=${printReceiptHandler(
        { ...loan, ...payment },
        {
          ...route,
          ...ownerConfig,
          ...common,
          printApp: "whatsapp",
          routePhone: route.phone,
          routePhone2: route.phone2,
        }
      )}`;
    } else {
      printReceiptHandler(
        { ...loan, ...payment },
        { ...route, ...ownerConfig, ...userConfig, ...common, routePhone: route.phone, routePhone2: route.phone2 }
      );
    }
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={loan}>
        <LoanDetailMenu loan={loan} />
      </DebtorNameHeader>

      <Box component="div" p={1}>
        <div style={{ borderStyle: "ridge", borderWidth: 4 }}>
          <div style={{ padding: 15, paddingRight: 25 }}>{parse(receipt)}</div>
        </div>

        <Box paddingTop={2}>
          <Button
            fullWidth
            startIcon={<PrintIcon size="1.3em" />}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handlePaymentPrint}>
            Imprimir Recibo
          </Button>
        </Box>

        <Box paddingTop={2}>
          <Button
            fullWidth
            classes={{ root: classes.green }}
            startIcon={<WhatsAppIcon size="1.3em" />}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleWhatsAppPrint}>
            Enviar WhatsApp
          </Button>
        </Box>

        <Box paddingTop={2}>
          <Button
            fullWidth
            disabled
            classes={{ root: classes.orange }}
            startIcon={<WhatsAppIcon size="1.3em" />}
            variant="outlined"
            color="primary"
            size="small">
            Modificar Pago
          </Button>
        </Box>

        <Box paddingTop={2} paddingBottom={2}>
          <Button
            fullWidth
            size="small"
            classes={{ root: classes.red }}
            startIcon={<DeleteForeverIcon size="1.3em" />}
            variant="outlined"
            color="default"
            onClick={() => history.push(`/delete/payment/${payment.payment_id}`)}>
            Borrar Pago
          </Button>
        </Box>

        <div className="mt-3">
          <RenderBackButton />
        </div>
      </Box>
    </div>
  );
};

export default PaymentReceipt;
