import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
// import { useSelector } from "react-redux";
// import { selectAuth } from "../store/slices/auth";
import { Redirect, useHistory } from "react-router-dom";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { Typography } from "@material-ui/core";
import { useAuth } from "../Hooks/useAuth";
// import { QuickPrinter } from "@shared/functions/print";

const Index = () => {
  const history = useHistory();
  const { data, isLoading } = useAuth();

  if (isLoading) return <FullLoadingScreen />;

  if (data.jwt !== null) return <Redirect to="/dashboard" />;

  const handlePrint = () => {
    const payment = `<CENTER><MEDIUM2>RECIBO DE PAGO!
<CENTER>17-diciembre-2024 - 05:18:06pm
<DLINE>
<MEDIUM2><BOLD>INVERSIONES WILSON
<NORMAL>Zona: Martes
Cobrado por: Wilson
(347) 812-7103
<LINE><RIGHT>Fecha: 17-dic-24

<BOLD><MEDIUM2>Jonathan Gonzalez Eustate

Prestamo No.: P305939
Cantidad Prestada $5,000
Ent: 12-nov-24 Ter: 21-ene-25

#L- Cuota Pagada ....3/10: $650
Total Monto Pagado:  $650
Atrasos Pendiente:   $1,300

<MEDIUM2>Balance: $4,550

<NORMAL>SIN RECIBO, NO HAY RECLAMOS!

<NORMAL>En caso de incumplimiento, sus
<NORMAL>datos seran procesados a data
<NORMAL>credito!`;

    const loanDetail = `<CENTER><MEDIUM2>DETALLE DE ENTREGA!
<CENTER>17-diciembre-2024 - 05:19:02pm
<DLINE>
<MEDIUM2><BOLD>INVERSIONES WILSON
<NORMAL>Zona: Martes
Cobrado por: Wilson
(347) 812-7103
<LINE><BOLD><MEDIUM2>Wilson Benavides Mercado

Fecha de Entrega:   17-dic-24

Cantidad Prestada:  $5,000

Tipo de Prestamo:   Semanal

Monto Cuota:        $500 X 13

Pago de Adelanto:   $0

Balance Anterior:   $0

Acta Notarial:     $0

Descuento Adicional: $0

Cantidad Entregada: $5,000

`;

    const loanCOntract = `<CENTER><MEDIUM2>CONTRATO DE PRESTAMO!
<CENTER>10-mayo-2024 - 01:19:02pm
<DLINE>
<MEDIUM2><BOLD>INVERSIONES BELLO AC
<NORMAL>Zona: La Vega
<LINE><BOLD><MEDIUM2>Rafaelina inmaculada Estrellas Paulino

Yo, Rafaelina inmaculada Estrellas Paulino, mayor de edad con la cedula de identidad No. 071-0042969-0. Al firmar este contrato renucio a cualquier derecho que pudiera tener en caso de incumplir el acuerdo de pago establecido por la empresa INVERSIONES BELLO AC, por lo que acepto pagar la totalidad del monto prestado de $92,000 mas los intereses, mora y gastos legales que pudieran producirse en caso de incumplimiento del presente contrato en el dia de hoy 10-may-24.
<LINE><BOLD>Detalle de Prestamo!
Fecha de Entrega:   10-may-24
Cantidad Prestada:  $92,000
Tipo de Prestamo:   Semanal
Monto Cuota:        $2,000 X 57
Pago de Adelanto:   $0
Balance Anterior:   $0
Acta Notarial:     $0
Descuento Adicional: $0
Cantidad Entregada: $92,000



<BOLD><MEDIUM2>________________________________

<CENTER><BOLD>Rafaelina inmaculada Estrellas Paulino
<RIGHT><BOLD>Firma del Cliente



<BOLD><MEDIUM2>________________________________

<RIGHT><BOLD>Firma del Garante`;

    QuickPrinter(loanCOntract);
  };

  return (
    <Card className="mt-5 mb-5" variant="outlined">
      <CardContent>
        {/* <Button onClick={handlePrint} fullWidth color="primary" variant="container">
          Imprimir Contracto
        </Button> */}
        <Typography className="mt-3" variant="h5" gutterBottom>
          Aplicacion de Cobro!
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          Todo los datos colectados durante el uso de esta aplicacion permancen guardados unicamente en su telefono, por
          esa razon la app puede trabajar sin internet.
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          La aplicacion puede trabajar sin internet, pero es recomendable conectarse al internet lo mas posible para que
          la aplicacion pueda guardar los datos en vivo.
        </Typography>

        {/* <Typography className="mt-3" variant="body1" paragraph>
          No me hago responsable por datos perdido por no cerrar Cuadre al terminar de Cobrar.
        </Typography> */}

        <Button className="mt-2" fullWidth variant="outlined" color="primary" onClick={() => history.push("/login")}>
          Iniciar Sesion
        </Button>
      </CardContent>
    </Card>
  );
};

export default Index;
