import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
// import Loading from "@shared/components/Loading";
import { Link } from "react-router-dom";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FaCheck } from "react-icons/fa";
import { MdBlock } from "react-icons/md";

const useStyles = makeStyles({
  table: {
    width: "90%",
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const MoreTools = () => {
  const classes = useStyles();

  return (
    <div className="container mt-4 mb-5">
      <DisplayAppEnvName />

      <div className="text-center mt-5 mb-3">
        <Link to="/upload/app/state" className="h6">
          1. Subir Estado de la aplicacion
        </Link>
      </div>

      <div className="text-center mt-4 mb-3">
        <Link to="/swr/fetcher" className="h6">
          2. Verificar Datos Descargado
        </Link>
      </div>

      <div className="text-center mt-4 mb-3">
        <Link to="/clear/app/data" className="h6">
          <span className="text-danger">3. Reiniciar Aplicaci&oacute;n (Borrar Todo)</span>
        </Link>
      </div>

      <div className="text-center mt-5 mb-5">
        <Typography variant="h6">Nuevo Almacenamiento!</Typography>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Sync</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="Loans">
                <TableCell component="th" scope="row">
                  Loans
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_loans_state") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_loans_sync") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
              </TableRow>

              <TableRow key="Debtors">
                <TableCell component="th" scope="row">
                  Debtors
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_debtors_state") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_debtors_sync") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
              </TableRow>

              <TableRow key="Payments">
                <TableCell component="th" scope="row">
                  Payments
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_payments_state") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localStorage.getItem("eps_payments_sync") === "1" ? (
                    <FaCheck size="1.2em" className="text-success" />
                  ) : (
                    <MdBlock size="1em" className="text-danger" />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MoreTools;
