import { createSlice } from "@reduxjs/toolkit";
import { setCommon } from "./common";

const initialState = {
  cuadre_id: null,
  payments: [],
  total: { collected: 0, collectedCash: 0, collectedDeposits: 0, mora: 0, count: 0 },
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // resetPayments: () => initialState,
    createPayment: (state, { payload }) => {
      const seqId = state.payments.length;
      state.payments.unshift({ ...payload, seqId, postData: undefined, syncProps: undefined });
      state.total.collectedCash += payload.paymentType === "cash" ? +payload.paymentAmount : 0;
      state.total.collectedDeposits += payload.paymentType === "deposit" ? +payload.paymentAmount : 0;
      state.total.collected += +payload.paymentAmount;
      state.total.mora += +payload.paymentMora;
      state.total.count += payload.paymentType === "cash" ? 1 : 0;
    },
    deletePayment: (state, { payload }) => {
      const payment = state.payments.find((z) => z.payment_id === payload.payment_id);
      const previousAmounts = state.payments.filter(
        (x) => x.money_id === payload.money_id && x.deleted !== true && x.seqId > payment.seqId && x.previousAmount > 0
      );

      state.payments.map((x) => {
        if (x.payment_id === payload.payment_id) {
          x.deleted = true;
          state.total.collectedCash -= x.paymentType === "cash" ? +x.paymentAmount : 0;
          state.total.collectedDeposits -= x.paymentType === "deposit" ? +x.paymentAmount : 0;
          state.total.collected -= +x.paymentAmount;
          state.total.mora -= +x.paymentMora;
          state.total.count -= x.paymentType === "cash" ? 1 : 0;
        }
      });

      previousAmounts.map((p) => {
        state.payments.map((x) => {
          if (x.payment_id === p.payment_id) {
            x.previousAmount -= payment.paymentAmount;
          }
        });
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(setCommon, (state, { payload }) => {
      if (state.cuadre_id === payload.cuadre_id) return state;
      return { ...initialState, cuadre_id: payload.cuadre_id };
    });
  },
});

export const selectTotalCollected = (state) => state.payments.total;
export const selectCollected = (state) => state.payments.payments.filter((x) => !x.deleted);
export const selectCollectedAll = (state) => state.payments.payments;
export const selectPaymentById = (state, payment_id) =>
  state.payments.payments.find((x) => x.payment_id === payment_id);

export const { createPayment, deletePayment } = paymentsSlice.actions;
export default paymentsSlice.reducer;
