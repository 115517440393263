import { useState, useMemo, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// import Calculator from "@shared/components/Calculator";
// import { getDebtorSelector } from "@shared/context/Selectors";
import { getUniqueID, getTimestamp, printAmount, replaceNonDigits } from "@shared/functions";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
import { printEntregaHandler, printLoanContractHandler } from "@shared/functions/print";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
import { MdOutlineVerified } from "react-icons/md";
// import DebtorFetch from "@shared/components/DebtorFetch";
// import DebtorNotAvailable from "@shared/components/DebtorNotAvailable";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderNewLoanForm from "@shared/components/RenderNewLoanForm";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import LoadingInspect from "@shared/components/LoadingInspect";
import { useSelector, useDispatch } from "react-redux";
import { createNewLoan, selectNewLoanByDebtorId } from "../store/slices/newLoans";
import { createQueue } from "../store/slices/syncronization";
import { selectCommon } from "../store/slices/common";
import { useGetDebtorsQuery, useGetUserConfigQuery } from "../store/slices/api";
// import CedulaWarning from "@shared/components/CedulaWarning";
import { Redirect, useHistory, Link } from "react-router-dom";
// import { alertError, alertSuccess } from "../store/slices/snackbar";
import { useGetOwnerConfigQuery, useGetRouteQuery, useGetUserQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import { selectNewDebtorById } from "../store/slices/newDebtors";
import { addDebtorsReordered } from "../store/slices/loans";
import { selectCreateStatus, setCreatingData } from "../store/slices/ui";
import usePouch from "../Hooks/usePouch";
import { pouches_db } from "../config";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (calc) => {
  return +calc.amount - +calc.actanotarial - +calc.balance - +calc.adelanto - +calc.discount;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "", discount: "" };

const LoanCreate = () => {
  const loans_db = usePouch(pouches_db.NEW_LOANS);
  const directUrl = useRef(null);
  const alert = useAlert();
  const history = useHistory();
  const { id: dUniqueId } = useParams();
  const dispatch = useDispatch();

  const [money_id, setMoneyId] = useState("n/a");

  const isDatacreating = useSelector(selectCreateStatus);

  const common = useSelector(selectCommon);
  const existingLoan = useSelector((state) => selectNewLoanByDebtorId(state, dUniqueId));
  const newDebtor = useSelector((state) => selectNewDebtorById(state, dUniqueId));

  const [formValues, setFormValues] = useState(initialFormValues);
  const [adelantoWarningAmount, setAdelantoWarningAmount] = useState(null);

  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const { data: user } = useGetUserQuery(common.user_id);
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  useEffect(() => {
    setMoneyId(getUniqueID(`NewLoan${common.creditor_id}`));
    //eslint-disable-next-line
  }, []);

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega({ ...formValues, balance: 0 });
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  if (isDatacreating === money_id) return <Redirect to={directUrl.current} />;

  if (isDatacreating === `creating${money_id}`) return <Loading />;

  const currentDebtor2 = debtors.find((x) => x.dUniqueId === dUniqueId);

  const currentDebtor = currentDebtor2 ?? newDebtor;

  if (+common.owner_id === 2587 && (!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1))
    return (
      <Box component="div" p={1} paddingTop={8}>
        <Alert variant="outlined" severity="warning">
          <Typography variant="h6" component="h4" gutterBottom>
            cliente Perfil sin C&eacute;dula!
          </Typography>
          <Typography variant="body1" component="p">
            Es requerido modificar el perfil de este cliente con su c&eacute;dula para poder hacer la entrega!
          </Typography>

          <Box paddingTop={3}>
            <Link to={`/debtor/edit/${currentDebtor.dUniqueId}`}>
              <Typography variant="body1">Modificar Perfil de cliente</Typography>
            </Link>
          </Box>
        </Alert>
      </Box>
    );

  if (!currentDebtor || !debtors || !ownerConfig || !userConfig || !route || !user)
    return (
      <LoadingInspect
        data={[
          {
            loaded: currentDebtor && Object.keys(currentDebtor).length > 0,
            label: "Current Debtor",
          },
          {
            loaded: debtors && debtors.length >= 0,
            label: "Debtors",
          },
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "OwnerConfig",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "Route",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "User Config",
          },
        ]}
      />
    );

  const initialValues = {
    user_id: common.user_id,
    debtor_id: currentDebtor.debtor_id,
    dUniqueId: currentDebtor.dUniqueId,
    name: currentDebtor.name,
    cedula: currentDebtor?.cedula,
    percentage: currentDebtor.percentage || route.rPerc || "",
    npayments: currentDebtor.npayments || route.rWeeks || "",
    amount: "",
    actanotarial: "",
    new_debtor: currentDebtor.new_debtor,
    creditor_id: common.creditor_id,
    given_date: common.collect_week_date,
    start_date: common.next_week_date,
    payoffBalance: 0,
    adelanto: 0,
    discount: 0,
    type: 7,
    confirmNewLoan: false,
  };

  const submithandler = (data) => submitnewLoan(data);

  let button = { label: "Guardar Prestamo", variant: "contained", color: "primary" };
  if (ownerConfig.digitalContract === "1") {
    //|| ownerConfig.loanIdentification === "1"
    button = {
      icon: <MdOutlineVerified />,
      label: "Guardar Prestamo y Continuar!",
      color: "primary",
      variant: "outlined",
    };
  }

  const submitnewLoan = async (data2) => {
    if ([837, 933].includes(+data2.creditor_id) && +data2.npayments !== 10) {
      alert.error("Solo prestamos a 10 Cuotas permitido!", { timeout: 7000, position: "top center" });
      return;
    }

    dispatch(setCreatingData({ data_id: `creating${dUniqueId}` }));
    const data = { ...data2, confirmNewLoan: undefined };

    if (entrega < 1) {
      // dispatch(alertError({ duration: 7000, message: "Cantidad a entregar es Invalida!" }));
      alert.error("Cantidad a entregar Invalida!", { timeout: 7000, position: "top center" });
      return;
    }

    if (data.actanotarial > (30 * data.amount) / 100) {
      // dispatch(alertError({ duration: 7000, message: "Acta Notarial muy alta!" }));
      alert.error("Actanotarial muy alta!", { timeout: 7000, position: "top center" });
      return;
    }

    if (+data.adelanto !== 0 && +data.adelanto !== adelantoWarningAmount) {
      setAdelantoWarningAmount(data.adelanto);
      return;
    }

    const timestamp = getTimestamp();
    const mUniqueId = data.new_debtor === true ? timestamp + data.user_id : timestamp + data.debtor_id;

    const newData = {
      ...data,
      money_id,
      timestamp,
      mUniqueId,
      wPayment: monto,
      creditor_id: common.creditor_id,
      collect_date: common.collect_date,
    };

    let syncProps = {
      data_id: money_id,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Date.now(),
      endPoint: "/bgsync/cobro/loan/create",
      syncPriority: 1,
      syncType: "createLoan",
      syncAmount: data.amount,
      syncTitle: "Prestamo Registrado",
      syncName: `Cliente > ${data.name}`,
      requiredData: true,
    };

    if (data.new_debtor) {
      syncProps = {
        data_id: money_id,
        syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
        syncPriority: 2,
        queueTime: Date.now(),
        endPoint: "/bgsync/cobro/debtor/loan/create",
        syncType: "createDebtorLoan",
        syncAmount: data.amount,
        syncTitle: "Cliente Registrado",
        syncName: `Nuevo Cliente > ${data.name}`,
        requiredData: true,
      };
    }

    // Permanently disabled option to take picture for new loan.
    // if (ownerConfig.loanIdentification === "1") {
    //   localStorage.setItem("syncProps", JSON.stringify(syncProps));

    //   if (data.new_debtor) {
    //     localStorage.setItem("newData", JSON.stringify({ debtor: currentDebtor, loan: newData }));
    //   } else {
    //     localStorage.setItem("newData", JSON.stringify(newData));
    //   }

    //   history.replace(`/camera/permission/${money_id}`);
    //   // history.replace(`/renewal/upload/${money_id}`);
    // } else

    if (ownerConfig.digitalContract === "1") {
      directUrl.current = `/loan/signature/${money_id}`;

      const putResult = await loans_db.putDoc({
        _id: mUniqueId,
        ...newData,
        eps_loans_state: localStorage.getItem("eps_loans_state"),
      });

      if (data.new_debtor) {
        dispatch(
          createQueue({ postData: { debtor: currentDebtor, loan: { ...newData, doc_rev: putResult?.rev } }, syncProps })
        );
      } else {
        dispatch(createQueue({ postData: { ...newData, doc_rev: putResult?.rev }, syncProps }));
      }

      dispatch(createNewLoan(newData));
      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      alert.success("Prestamo Guardado!", { position: "top center" });
      // history.replace(`/loan/signature/${money_id}`);
    } else {
      directUrl.current = `/entregas`;

      const putResult = await loans_db.putDoc({
        _id: mUniqueId,
        ...newData,
        eps_loans_state: localStorage.getItem("eps_loans_state"),
      });

      if (data.new_debtor) {
        dispatch(
          createQueue({ postData: { debtor: currentDebtor, loan: { ...newData, doc_rev: putResult?.rev } }, syncProps })
        );
      } else {
        dispatch(createQueue({ postData: { ...newData, doc_rev: putResult?.rev }, syncProps }));
      }

      dispatch(createNewLoan(newData));
      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      alert.success("Prestamo Guardado!", { position: "top center" });
      // history.replace(`/entregas`);
    }
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentDebtor}>
        <LoanDetailMenu loan={currentDebtor} />
      </DebtorNameHeader>

      {/* {(!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1) && (
        <CedulaWarning
        debtor={currentDebtor}
        updateDebtorCedula={updateDebtorCedula}
        mutate={mutate}
          redirect={`/loan/create/${currentDebtor.dUniqueId}`}
        />
      )} */}

      <Box component="div" p={1}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6" component="h4">
              Nuevo Prestamo!
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <Calculator /> */}
          </Grid>
        </Grid>

        <Box paddingBottom={1}>
          <Divider />
        </Box>

        {existingLoan ? (
          <div className="mt-2 mb-5">
            <Box paddingBottom={1}>
              <Grow direction="up" in>
                <Alert variant="outlined" severity="error">
                  <Typography variant="body1" component="p" className="font-weight-bold">
                    Este cliente, ya tiene un nuevo prestamo registrado!
                  </Typography>
                </Alert>
              </Grow>
            </Box>
          </div>
        ) : (
          <>
            <Box div="div" p={1}>
              <RenderNewLoanCalculation data={{ monto, entrega, profit }} />
            </Box>

            <Box div="div" p={1}>
              <RenderNewLoanForm
                setFormValues={setFormValues}
                initialValues={initialValues}
                submithandler={submithandler}
                button={button}>
                {adelantoWarningAmount && (
                  <Box paddingBottom={1}>
                    <Grow direction="up" in>
                      <Alert variant="outlined" severity="warning">
                        <Typography variant="body1" component="p">
                          Esta aplicado <b>un pago de adelanto de {printAmount(adelantoWarningAmount)}</b>, si quiere
                          aplicar le este adelanto de {printAmount(adelantoWarningAmount)} trate nuevamente!
                        </Typography>
                      </Alert>
                    </Grow>
                  </Box>
                )}
              </RenderNewLoanForm>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default LoanCreate;
