import { useEffect, useState } from "react";
import PouchDB from "pouchdb-browser";

const usePouchReplication = (localDb, remoteDb = null, options = {}) => {
  const [syncState, setSyncState] = useState({
    syncActive: false,
    lastError: null,
    replicationStatus: null,
  });

  useEffect(() => {
    if (!remoteDb) return;

    let pouchDbSyncActiveEvent = false;
    let pouchDbSyncChangeEvent = false;

    const repHandler = PouchDB.replicate(localDb, `${import.meta.env.VITE_COUCH_URL}/${remoteDb}`, {
      live: true,
      retry: true,
      ...options,
    });

    // Update sync state
    setSyncState((prev) => ({ ...prev, syncActive: true }));

    // Event listeners
    repHandler
      .on("active", () => {
        pouchDbSyncActiveEvent = true;
        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: "Replication active.",
        // }));
      })
      .on("change", (info) => {
        pouchDbSyncChangeEvent = true;
        // console.log(`Change detected on ${localDb}: ${JSON.stringify(info)}`);
        // console.log(info.change.docs[0]._id);
        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: `Change detected: ${JSON.stringify(info)}`,
        // }));
      })
      .on("paused", (err) => {
        if (pouchDbSyncActiveEvent == true && pouchDbSyncChangeEvent == false) {
          // Gotcha! Syncing with remote DB not happening!
          console.log("Gotcha! Syncing with remote DB not happening!");
        } else {
          // Everything's ok. Syncing with remote DB happening normally.
        }

        pouchDbSyncActiveEvent = false;
        pouchDbSyncChangeEvent = false;

        // setSyncState((prev) => ({
        //   ...prev,
        //   replicationStatus: "Replication paused.",
        //   lastError: err || null,
        // }));
      })
      .on("error", (err) => {
        console.error("Replication error:", err);
        setSyncState((prev) => ({
          ...prev,
          syncActive: false,
          lastError: err,
        }));
      });

    // Cleanup on unmount
    return () => {
      repHandler.cancel();
      setSyncState((prev) => ({
        ...prev,
        syncActive: false,
        replicationStatus: "Replication stopped.",
      }));
    };
  }, [localDb, remoteDb]);

  return syncState;
};

export default usePouchReplication;
