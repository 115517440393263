import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@shared/server/api";
// import { getLocalToken } from "@shared/functions";
import { logout } from "./auth";

// export const fetchCommonThunk = createAsyncThunk("common/fetch", async ({ user, jwt }, { rejectWithValue }) => {
//   const token = getLocalToken();
//   const { creditor_id, user_id } = user;

//   try {
//     const { data } = await api.post("/cobro/cuadre/init", {
//       creditor_id,
//       user_id,
//       owner_id: user.owner_id,
//       token,
//       env: import.meta.env.VITE_ENV,
//       appVersion: import.meta.env.VITE_VERSION,
//     });

//     const { owner_id, collect_date, session_id } = data;
//     localStorage.setItem("localCommon", JSON.stringify({ owner_id, user_id, collect_date, creditor_id, session_id }));

//     return { ...data, jwt };
//   } catch (error) {
//     if (error.response) {
//       throw rejectWithValue({ message: error.response.data.message });
//     } else {
//       throw rejectWithValue({ message: error.message });
//     }
//   }
// });

const initialState = { cuadre_id: null, common: {}, error: null, status: "idle", lastFetch: null };

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCommon: () => initialState,
    setCommon: (state, action) => {
      state.common = action.payload;
    },
    updateBank: (state, action) => {
      state.common.bank_amount = action.payload.bankAmount;
    },
    unLockCuadre: (state, action) => {
      state.common.perm_bank = true;
      // state.common.perm_cuadre = true; // discontinued
      state.common.perm_efectivo = true;
      state.common.perm_cuadreAmount = true;
    },
    updateLastCheckUpdate: (state, action) => {
      state.common.lastCheckUpdate = action.payload.lastCheckUpdate;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(logout.type, () => initialState)
  //     .addCase(fetchCommonThunk.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
  //       state.error = null;
  //       state.cuadre_id = payload.cuadre_id;
  //       state.status = "succceed";
  //       state.lastFetch = Date.now();
  //       state.common = payload;
  //     })
  //     .addCase(fetchCommonThunk.rejected, (state, action) => {
  //       state.error = action.payload.message;
  //       state.status = "failed";
  //     });
  // },
});

export const selectCommon = (state) => state.common.common;
export const selectCommonStatus = (state) => state.common.status;
export const selectCommonError = (state) => state.common.error;

export const { setCommon, resetCommon, updateBank, updateLastCheckUpdate, unLockCuadre } = commonSlice.actions;
export default commonSlice.reducer;
