import { useEffect, useState } from "react";
import FloatingButton from "@shared/components/FloatingButton";
import { getUniqueID } from "@shared/functions";
import { useAlert } from "react-alert";
import Loading from "@shared/components/Loading";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { MdOutlineCloudOff, MdOutlineCloudQueue } from "react-icons/md";
import usePouchQueues from "../Hooks/usePouchQueues";
import usePouch from "../Hooks/usePouch";
import usePouchChanges from "../Hooks/usePouchChanges";
import { pouches_db, syncStatus } from "../config";
import { syncTypes } from "../components/sync/getDataType";
import useDataList from "../Hooks/useDataList";

const PouchTesting = () => {
  const queue_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const conflicts_db = usePouch(pouches_db.SYNC_CONFLICTS);
  const { data: syncs, isLoading: isLs, refresh: refreshHistory } = useDataList(pouches_db.SYNC_HISTORY);
  const { data: conflicts, isLoading: isLc, refresh: refreshConflicts } = useDataList(pouches_db.SYNC_CONFLICTS);

  const conflictChanges = usePouchChanges(pouches_db.SYNC_CONFLICTS);

  useEffect(() => {
    refreshConflicts();
  }, [conflictChanges]);

  const alert = useAlert();

  const [dependsOn, setDependsOn] = useState(null);

  const handleQueueCreate = async () => {
    const syncronization_id = getUniqueID();

    const data = {
      _id: syncronization_id,
      triesCount: 0,
      dependsOn,
      syncPriority: Math.floor(Math.random() * 1000),
      syncType: syncTypes.TESTING,
      postData: {
        name: "Jose",
        amount: 100,
        Category: "food",
      },
    };

    setDependsOn(syncronization_id);

    const syncHistory = {
      _id: syncronization_id,
      data_id: data.gUniqueId,
      syncType: syncTypes.TESTING,
      syncAmount: data.amount,
      syncTitle: "Testing Tittle",
      syncName: `Descripcion > Gatso eliminado`,
    };

    const result = await queue_db.putSertQueue(data);
    await history_db.putSertHistory(syncHistory);

    refreshHistory();

    if (result.ok !== true) {
      alert.error("Data not Registrada!", { position: "bottom center" });
    }
  };

  const deleteSyncHistory = async (doc) => {
    const result = await history_db.deleteDoc(doc._id);
    refreshHistory();

    if (result.ok !== true) {
      alert.error("Gasto no Borrado!");
    }
  };

  const deleteConflicts = async () => {
    await conflicts_db.deleteDatabase();
    refreshConflicts();
  };

  const deleteHistory = async () => {
    await history_db.deleteDatabase();
    refreshHistory();
  };

  const deleteQueues = async () => {
    await queue_db.deleteDatabase();
  };

  if (isLc || isLs) return <Loading />;

  // console.log(syncs);
  // console.log(conflicts);
  return (
    <>
      <div className="container mt-3 mb-5">
        <span className="text-info" onClick={handleQueueCreate}>
          <FloatingButton icon={<AddIcon />} tooltip="Crear Gasto" goto="" />
        </span>

        <table className="table">
          <tbody>
            <tr>
              <td>
                <Button variant="outlined" color="secondary" onClick={deleteQueues}>
                  Clear Queues
                </Button>
              </td>
              <td>
                <Button variant="outlined" color="secondary" onClick={deleteHistory}>
                  Clear History
                </Button>
              </td>
              <td>
                <Button variant="outlined" color="secondary" onClick={deleteConflicts}>
                  Clear Conflicts
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Queue Data ({syncs.length})</h3>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">_id</th> */}
              <th scope="col">Type</th>
              <th scope="col">sync</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {syncs.map((gasto, index) => (
              <tr key={gasto._id}>
                <td className="font-italic">{index + 1}</td>
                {/* <td className="font-italic">{gasto._id}</td> */}
                <td className="font-italic">{gasto.syncTitle}</td>
                <td className="font-italic">
                  {gasto.syncStatus === syncStatus.COMPLETED && (
                    <MdOutlineCloudQueue className="text-success" size="1em" />
                  )}
                  {gasto.syncStatus === syncStatus.FAILED && <MdOutlineCloudOff className="text-danger" size="1em" />}
                  {gasto.syncStatus === syncStatus.PENDING && <MdOutlineCloudOff size="1em" />}
                </td>
                <td onClick={() => deleteSyncHistory(gasto)}>Delete</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="container mt-3 mb-5">
        <h3>Queue Conflicts({conflicts.length})</h3>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">_id</th> */}
              <th scope="col">_id</th>
              <th scope="col">sync</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {conflicts.map((row, index) => (
              <tr key={index}>
                <td className="font-italic">{JSON.stringify(row)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PouchTesting;
