import { pouches_db, syncStatus } from "../config"; //localStorageCommon
import PouchDB from "pouchdb-browser";

export default function usePouchQueues(dbName) {
  const getAllDocs = async (options) => {
    try {
      const db = new PouchDB(dbName);
      const result = await db.allDocs({ include_docs: true, ...options });
      return result.rows.map((row) => row.doc);
    } catch (error) {
      console.error("Error fetching all docs:", error);
      return [];
    }
  };

  const createNewQueue = async (data, options = {}) => {
    // const localObject = localStorage.getItem(localStorageCommon);

    const common_db = new PouchDB(pouches_db.OFFLINE_DATA);
    const common = await common_db.get("common");

    let newPostData = data.postData;
    if (common) {
      const { creditor_id, collect_date, user_id } = common;
      newPostData = { ...data.postData, creditor_id, collect_date, user_id };
    }

    try {
      let newData = { ...data, postData: newPostData };
      newData.triesCount = 0;
      newData.queuedTime = Date.now();
      newData.replayAfter = Date.now();

      const db = new PouchDB(dbName);
      return await db.put(newData, options);
    } catch (err) {
      console.log("Error creating create doc", err);
      throw new Error(err);
    }
  };

  const putSertQueue = async (data, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      const doc = await getDocById(data._id);
      if (doc) return await db.put({ ...doc, ...data }, { force: true, ...options });

      return await createNewQueue(data, { force: true, ...options });
    } catch (error) {
      console.log("Error in putSertQueue function:", error);
      throw new Error(error);
    }
  };

  const putSertHistory = async (data, options = {}) => {
    const newDoc = { ...data, syncStatus: syncStatus.PENDING };

    try {
      const db = new PouchDB(dbName);
      const doc = await getDocById(data._id);
      if (doc) return await db.put({ ...doc, ...newDoc }, options);

      return await db.put(newDoc, options);
    } catch (error) {
      console.log("Error in putSertHistory function:", error);
      throw new Error(error);
    }
  };

  const getDocById = async (docId, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      return await db.get(docId, options);
    } catch (err) {
      // console.log("Error fetch single doc", err);
      return null;
    }
  };

  const putDoc = async (doc, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      return await db.put(doc, options);
    } catch (err) {
      console.log("error in putDOc:", err);
      throw new Error(err);
    }
  };

  const compact = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.compact();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDoc = async (docId) => {
    try {
      const db = new PouchDB(dbName);
      const doc = await db.get(docId);
      doc._deleted = true;

      const result = await db.put(doc);
      return result;
    } catch (err) {
      console.log("Error while deleting doc:", err);
      throw new Error(err);
    }
  };

  const getDatabaseInfo = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.info();
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const deleteDatabase = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.destroy(dbName);
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  return {
    compact,
    getDocById,
    putSertQueue,
    putSertHistory,
    getAllDocs,
    putDoc,
    deleteDoc,
    getDatabaseInfo,
    deleteDatabase,
  };
}
