// import "./wdyr";
// import { StrictMode } from "react";
import logger from "./logService";
import ReactDOM from "react-dom";
// import { SWRConfig } from "swr";
import App from "./App";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { BrowserRouter } from "react-router-dom";
import register from "./registerServiceWorker";
// import { Provider as ContextProvider } from "@shared/context/DataContext";
import { Provider as ReduxProvider } from "react-redux";
import store, { persistedStore } from "./store";
import "bootstrap/dist/css/bootstrap.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ProvideAuth } from "./Hooks/useAuth";

// if (import.meta.env.VITE_ENV === "beta") logger.initLogServices();

// navigator.getUserMedia(
//   {
//     video: true,
//     // audio: true,
//   },

//   // successCallback
//   function () {},

//   // errorCallback
//   function (err) {
//     if (err.message === "Permission denied") {
//       //   // Explain why you need permission and how to update the permission setting
//     }
//   }
// );

// optional configuration
const defaultOptions = {
  position: "bottom center",
  timeout: 5000,
  offset: "5px",
  transition: "fade", //scale
};

logger.initLogServices();

ReactDOM.render(
  // <StrictMode>
  <BrowserRouter>
    <AlertProvider template={AlertTemplate} {...defaultOptions}>
      <ReduxProvider store={store}>
        <PersistGate
          persistor={persistedStore}
          loading={
            <div className="container mt-5">
              <FullLoadingScreen />
            </div>
          }
        >
          <ProvideAuth>
            <App />
          </ProvideAuth>
        </PersistGate>
      </ReduxProvider>
    </AlertProvider>
  </BrowserRouter>,
  // </StrictMode>,
  document.getElementById("root")
);

register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

function sendToAnalytics(metric) {
  // const body = JSON.stringify(metric);
  // const url = "https://www.googletagmanager.com/gtag/js?id=G-66WCRSZJLR";
  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  // if (navigator.sendBeacon) {
  //   navigator.sendBeacon(url, body);
  // } else {
  // fetch(url, { body, method: "POST", mode: "no-cors", keepalive: true });
  // }
}
