import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@nodes/@material-ui/core/Typography";
import PeopleIcon from "@material-ui/icons/People";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { FaUserAlt } from "react-icons/fa";
import CachedIcon from "@material-ui/icons/Cached";
import { pouches_db } from "../config";
import useDataObject from "../Hooks/useDataObject";
import usePouch from "../Hooks/usePouch";
import Loading from "@shared/components/Loading";
import api from "@shared/server/api";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function OfflineData() {
  const alert = useAlert();
  const classes = useStyles();

  const [loader, setLoader] = useState({ debtors: false, user: false, route: false, configs: false });

  const debtors_db = usePouch(pouches_db.DEBTORS);
  const offline_db = usePouch(pouches_db.OFFLINE_DATA);

  const { data: common, isLoading: c_loading } = useDataObject(pouches_db.OFFLINE_DATA, "common");

  const { data: user, isLoading: u_loading } = useDataObject(pouches_db.OFFLINE_DATA, `w${common?.user_id}`);

  const { data: route, isLoading: r_loading } = useDataObject(pouches_db.OFFLINE_DATA, `r${common?.creditor_id}`);

  if (u_loading || r_loading || c_loading) return <Loading />;

  const fetchDebtors = async () => {
    setLoader((prevSTate) => ({ ...prevSTate, debtors: true }));
    try {
      await debtors_db.deleteDatabase();

      const { data: debtorsNew } = await api.get(`/debtors/${common.creditor_id}/${common.user_id}`);

      debtorsNew.forEach(function (row) {
        row._id = row.debtor_id;
      });
      await debtors_db.bulkDocs(debtorsNew);

      alert.success("Listado de Clientes Actualizado!", { timeout: 4000, position: "bottom center" });
    } catch (error) {
      console.log(error);
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    } finally {
      setLoader((prevSTate) => ({ ...prevSTate, debtors: false }));
    }
  };

  const fetchUser = async () => {
    setLoader((prevSTate) => ({ ...prevSTate, user: true }));
    try {
      const { data: userNew } = await api.get(`/user/${common.user_id}`);
      await offline_db.putSert({ _id: `w${common.user_id}`, ...userNew });

      alert.success("Usuario Actualizado!", { timeout: 4000, position: "bottom center" });
    } catch (error) {
      console.log(error);
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    } finally {
      setLoader((prevSTate) => ({ ...prevSTate, user: false }));
    }
  };

  const fetchRoute = async () => {
    setLoader((prevSTate) => ({ ...prevSTate, route: true }));
    try {
      const { data: routeNew } = await api.get(`/route/info/${common.creditor_id}/${common.user_id}`);
      await offline_db.putSert({ _id: `r${common.creditor_id}`, ...routeNew });

      alert.success("Informacion de ruta Actualizada!", { timeout: 4000, position: "bottom center" });
    } catch (error) {
      console.log(error);
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    } finally {
      setLoader((prevSTate) => ({ ...prevSTate, route: false }));
    }
  };

  const fetchAllConfigs = async () => {
    setLoader((prevSTate) => ({ ...prevSTate, configs: true }));
    try {
      const { data: ownerConfigNew } = await api.get(`/owner/configv2/${common.owner_id}`);
      const { data: userConfigNew } = await api.get(`/user/configv2/${common.owner_id}`);
      const { data: permissionsNew } = await api.get(`/user/route/permissions/${common.user_id}/${common.creditor_id}`);

      await offline_db.putSert({ _id: `oc${common.owner_id}`, ...ownerConfigNew });
      await offline_db.putSert({ _id: `uc${common.owner_id}`, ...userConfigNew });
      await offline_db.putSert({ _id: `p${common.user_id}${common.creditor_id}`, ...permissionsNew });

      alert.success("Configuraciones Actualizada!", { timeout: 4000, position: "bottom center" });
    } catch (error) {
      console.log(error);
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    } finally {
      setLoader((prevSTate) => ({ ...prevSTate, configs: false }));
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" paragraph align="center">
        Datos Descargando!
      </Typography>

      <List
        subheader={
          <Typography variant="body1" paragraph align="center">
            Los siguientes datos estan estan almacenado en este dispositivo!
          </Typography>
        }
        className={classes.root}>
        <ListItem>
          <ListItemIcon>{<FaUserAlt size="1.3em" />}</ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Info de Usuario"
            secondary={`${user?.fname} ${user?.lname}`}
          />

          {loader.user ? (
            <CircularProgress size="1.4em" />
          ) : (
            <IconButton onClick={fetchUser} edge="end">
              <CachedIcon />
            </IconButton>
          )}
        </ListItem>

        <ListItem>
          <ListItemIcon>{<HomeIcon size="1.5em" />}</ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Info de Ruta"
            secondary={`${route?.route_name} (${route?.location})`}
          />

          {loader.route ? (
            <CircularProgress size="1.4em" />
          ) : (
            <IconButton onClick={fetchRoute} edge="end">
              <CachedIcon />
            </IconButton>
          )}
        </ListItem>

        <ListItem>
          <ListItemIcon>{<PeopleIcon />}</ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Lista de Clientes"
            secondary="Lista de todo los clientes con o sin prestamos!"
          />

          {loader.debtors ? (
            <CircularProgress size="1.4em" />
          ) : (
            <IconButton onClick={fetchDebtors} edge="end">
              <CachedIcon />
            </IconButton>
          )}
        </ListItem>

        <ListItem>
          <ListItemIcon>{<SettingsApplicationsIcon />}</ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Configuraciones"
            secondary="Todas las configuraciones para este usuario y esta ruta"
          />

          {loader.configs ? (
            <CircularProgress size="1.6em" />
          ) : (
            <IconButton onClick={fetchAllConfigs} edge="end">
              <CachedIcon />
            </IconButton>
          )}
        </ListItem>
      </List>
    </div>
  );
}
