import { useState } from "@nodes/react";
import Modal from "@nodes/react-bootstrap/Modal";
import Typography from "@nodes/@material-ui/core/Typography";
import Button from "@nodes/@material-ui/core/Button";
// import { forceReload } from "@shared/functions";

const ShowAppUpdateRequired = ({ forceUpdatePWA }) => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const doForceRefresh = () => {
    handleClose();
    // forceReload();
    forceUpdatePWA();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <Modal.Title id="example-custom-modal-styling-title">
            <Typography variant="h5">Actualizacion Disponible!</Typography>
          </Modal.Title>
          <Typography variant="body1" className="mt-2" paragraph>
            Estas usando una version antigua de la aplicacion, haga clic en el boton refrescar y 30 Segundos.
            <Typography variant="body2" className="mt-2">
              Despues de 30 segundos trate de ingresar nuevamente.
            </Typography>
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <div className="container">
            <div className="row">
              <div className="col text-right">
                <Button variant="contained" color="primary" onClick={doForceRefresh}>
                  Refrescar!
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowAppUpdateRequired;
