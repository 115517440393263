import { createSlice, createAsyncThunk, isAnyOf, isAllOf } from "@reduxjs/toolkit";
import api from "@shared/server/api";
// import { getLocalToken, spanishErrors } from "@shared/functions";
// import { localStorageRememberUser } from "@/config";
// import { fetchCommonThunk } from "./common";
// import Axios from "axios";

// export const authThunk = createAsyncThunk("auth/login", async ({ data }, { rejectWithValue, dispatch }) => {
//   const token = getLocalToken();

//   let ipAddress = "1.0.0.27";

//   try {
//     if (import.meta.env.PROD) {
//       let { data: remoteClient } = await Axios.get("https://ipapi.co/json/");
//       ipAddress = remoteClient.ip;
//     }

//     const { data: rData } = await api.post("/cobro/login/v2", {
//       data: { ...data, token, ipAddress },
//       foceAppUpdateNumber: import.meta.env.VITE_foceAppUpdateNumber,
//     });

//     if (rData.action !== "routeDisabled" && data.rememberUsername) {
//       const deviceObj = { username: data.username, firstname: rData.user.firstname, user_id: rData.user.user_id };
//       localStorage.setItem(localStorageRememberUser, JSON.stringify(deviceObj));
//     }

//     if (rData.action !== "routeDisabled") {
//       dispatch(fetchCommonThunk(rData));
//     }

//     return rData;
//   } catch (error) {
//     if (error.response) {
//       throw rejectWithValue({ message: error.response.data.message, username: data.username });
//     } else {
//       throw rejectWithValue({ message: error.message, username: data.username });
//     }
//   }
// });

// export const diagnosticThunk = createAsyncThunk("auth/diagnostic", async (data, thunkAPI) => {
//   const { user_id, owner_id, creditor_id, collect_date } = thunkAPI.getState().common.common;

//   const token = getLocalToken();
//   await backend.post("/insert/app/diagnostic", {
//     collector_id: user_id,
//     mainApp,
//     owner_id,
//     creditor_id,
//     collect_date,
//     page: data.page,
//     appVersion: import.meta.env.VITE_VERSION,
//     env: import.meta.env.VITE_ENV,
//     token,
//   });

//   return data;
// });

const initialState = {
  jwt: null,
  customer_id: null,
  user_id: null,
  creditor_id: null,
  status: "idle",
  commonStatus: "idle",
  username: "",
  error: null,
  lastFetch: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    setAuth: (state, action) => {
      state.jwt = action.payload.jwt;
      state.user_id = action.payload.user.user_id;
      state.creditor_id = action.payload.user.creditor_id;
    },
    resetAuth: (state, action) => {
      state.username = "";
      state.status = "idle";
      state.commonStatus = "idle";
      state.error = null;
      state.lastFetch = null;
    },
    unSetCustomerId: (state, action) => {
      state.customer_id = null;
    },
  },
  extraReducers(builder) {
    // builder
    //   .addCase(authThunk.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(authThunk.fulfilled, (state, { payload }) => {
    //     state.error = null;
    //     state.status = "succceed";
    //     state.lastFetch = Date.now();
    //     state.username = "";
    //     if (payload.action === "routeDisabled") {
    //       state.customer_id = payload.customer.customer_id;
    //     } else {
    //       // state.jwt = payload.jwt;
    //       state.user_id = payload.user.user_id;
    //       state.creditor_id = payload.user.creditor_id;
    //     }
    //   })
    //   .addCase(authThunk.rejected, (state, action) => {
    //     state.error = spanishErrors(action.payload.message);
    //     state.username = action.payload.username;
    //     state.status = "failed";
    //   });
    // .addCase(fetchCommonThunk.pending, (state, { payload }) => {
    //   state.commonStatus = "loading";
    // })
    // .addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
    //   state.jwt = payload.jwt;
    //   state.error = null;
    //   state.commonStatus = "succceed";
    // })
    // .addCase(fetchCommonThunk.rejected, (state, action) => {
    //   state.error = spanishErrors(action.payload.message);
    //   state.commonStatus = "failed";
    // });
    // .addCase(diagnosticThunk.fulfilled, (state, { payload }) => {
    //   if (payload.page === "logout") {
    //     return initialState;
    //   } else {
    //     state.diagnostic = "succceed";
    //   }
    // })
    // .addCase(diagnosticThunk.pending, (state) => {
    //   state.diagnostic = "pending";
    // });
  },
});

export const selectAuth = (state) => state.auth;

export const { setAuth, logout, unSetCustomerId, resetAuth } = authSlice.actions;
export default authSlice.reducer;
